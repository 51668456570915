import React, { useContext, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TagInput, SubTitle, Spacer, Button, Theme, Icon } from "@dspworkplace/ui";
import SettingsContext from "../context";
import { getCompanyId, showToast } from "../../../Utilities";
import { useAuth } from "../../../Auth";
import Loading,{ LoadingView } from "../../../components/Loading";
import { debugLog } from "../../../AppTwilioConversation/utils";
import { AccessCheck, IsGranted } from "../../../security";
import Modal from "../../../components/Modal";
import SimpleScroll from "../../../components/SimpleScroll";
import { dialogPromise } from "../../../components/Dialog";
import moment from "moment";
import { RowTimeline } from "../../Employees/timeLine";


const TagInputContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.templateColumns ? props.templateColumns : "auto auto")};
    grid-column-gap: ${props => (props.columnGap ? props.columnGap : "8px")};
    width: ${props => (props.width ? props.width : "612px")};
    align-items: center;

    label {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div {
        width: ${props => (props.width ? props.width : "612px")};
    }

    div.tagEventPrevent {
        width: ${props => (props.width ? props.width : "612px")};
    }

    ul {
        max-width: ${props => (props.width ? props.width : "612px")};
    }

    ul.big {
        max-width: unset;
        width: auto;
    }

    input {
        width: 100%;
    }
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const DateContent = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: bold;
    color: ${Theme.colors.neutrals.medium};
`;

function getStateForDefaultAutoUsersChat(state) {
    try {
        let defaultAutoUsersChat = Array.isArray(state.company.defaultAutoUsersChat) ? state.company.defaultAutoUsersChat : [];
        return { defaultAutoUsersChat };
    } catch (e) {
        console.error({ getStateForDefaultAutoUsersChat: e });
        return { defaultAutoUsersChat: [] };
    }
}

function formatForTagInputForUser(list: any[], user: any) {
    try {
        // console.log(user);
        if (list.includes(user.userId)) {
            return { name: user.friendlyName, value: user.userId, selected: true };
        } else {
            return { name: user.friendlyName, value: user.userId };
        }
    } catch (e) {
        console.error({ formatForTagInputForUser: e, list, user });
        return { name: user.friendlyName, value: user.userId };
    }
}

async function fetchDefaultAutoUsersChat(api, state, setDefaultUsersToChat) {
    try {
        debugLog('fetchBotManagers');
        let { data } = await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            notEqualRole: 'ROLE_DELIVERY_ASSOCIATE'
        });
        let users: any[] = data.users;
        debugLog({ fetchDefaultAutoUsersChat: users });
        let { defaultAutoUsersChat } = getStateForDefaultAutoUsersChat(state);
        let tags: any[] = [];
        let user;
        for (let i = 0; i < users.length; i++) {
            user = users[i];
            tags.push(formatForTagInputForUser(defaultAutoUsersChat, user));
        }
        setDefaultUsersToChat(tags);
        return true
    } catch (e) {
        console.error({ fetchBotManagers: e });
        return false;
    }
}

async function updateDefaultAutoUsersChat(api, defaultAutoUsersChat) {
    try {
        await api.post('/api/bot/twilio/employees', {
            companyId: getCompanyId(),
            update: 'defaultAutoUsersChat',
            defaultAutoUsersChat,
        });
        return true
    } catch (e) {
        return false;
    }
}

const DefaultAutoUsers = () => {
    // @ts-ignore
    const { api } = useAuth();
    // @ts-ignore
    const [state, dispatch] = useContext(SettingsContext);
    // console.log({DefaultAutoUsers: state});
    const { register, handleSubmit } = useForm();
    const [defaultUsersToChat, setDefaultUsersToChat] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);

    const onSubmit = async (data, evt) => {
        let form = evt.target;
        try {
            // console.log('onSubmit');
            // console.log(data.defaultAutoUsersChat);
            setLoadingSave(true);
            form.querySelector('button').disabled = true;
            let success = await updateDefaultAutoUsersChat(api, data.defaultAutoUsersChat);
            if (success) {
                let updatedCompany = state.company;
                updatedCompany['defaultAutoUsersChat'] = data.defaultAutoUsersChat;
                updatedCompany['showMobileReleaseShift'] = updatedCompany.showMobileReleaseShift;
                updatedCompany['showMobileSwapShift'] = updatedCompany.showMobileSwapShift;
                dispatch({ type: 'UPDATE_COMPANY', payload: { company: updatedCompany } });
                await showToast({
                    type: 'success',
                    title: 'Default Auto Users Chat',
                    content: 'Updates completed!'
                });
            }
            form.querySelector('button').disabled = false;
            setLoadingSave(false);
        } catch (e) {
            await showToast({ type: 'error', title: 'Default Auto Users Chat', content: JSON.stringify(e) });
            form.querySelector('button').disabled = false;
            setLoadingSave(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchDefaultAutoUsersChat(api, state, setDefaultUsersToChat).then(() => {
            setLoading(false);
        });
    }, [state.company]);

    if (loading) {
        return <LoadingView containerWidth={'400px'} imgWidth={'40px'} />
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Default Users for Group Chats/Single and Group Chat Administrators</SubTitle>
            <Spacer bottom={4} />
            <RowContainer>
                <TagInputContainer>
                    <TagInput
                        label={"Select user:"}
                        options={defaultUsersToChat}
                        size="big"
                        name={'defaultAutoUsersChat'}
                        ref={register}
                        // @ts-ignore
                        placeholder={defaultUsersToChat.filter(e => e.selected).length === 0 ? "Default to Operation Account Manager users if they exist" : ""}
                    />
                </TagInputContainer>
            </RowContainer>
            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.U]}>
                <Spacer bottom={5} />
                <Button type='primary'>{loadingSave ? 'Saving ...' : 'Save'}</Button>
                <Spacer top={3} left={10} inline >
                    <Icon.TimeLine
                        color={Theme.colors.info.border}
                        size="20px"
                        onClick={() => {
                            Timeline(getCompanyId(),api);
                        }}
                    />
                </Spacer>
            </IsGranted>
        </form>
    );
};

const TimeLineDailog = ({ success, cancel, companyId, api }) => {
    const [loading, setLoading] = useState(false);
    const [timelineData, setTimelineData] = useState([]);

    const fetchTimeLine = async () => {
        setLoading(true);
        let params = {
            actions: {
                response: {
                    SettingUpdateLog: {
                        custom: {
                            functionName: "getCompanyTimeLine",
                            criteria: {
                                company: companyId,
                            },
                            get: "timeLineData",
                        },
                    },
                },
            },
        };
        let response = await api.post("/api/lazy/manage/data", params);
        
        setTimelineData(response.data.data.timeLineData.filter((i) => i.line.some((e) => e.createdAt !== null)));
        setLoading(false);
    };

    useEffect(() => {
        fetchTimeLine();
    }, []);

    return (
        <Modal width="480px" title="Timeline" visible={true} setVisible={cancel} disabled={loading}>
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <Loading style={{ width: "40px" }} />
                </div>
            ) : (
                <>
                    <SimpleScroll>
                        {timelineData.length > 0 ? (
                            <SmartTimeLine data={timelineData} />
                        ) : (
                            <div style={{ textAlign: "center", fontSize: "16px", color: "#707070" }}>
                                No timeline found for this driver!!!
                            </div>
                        )}
                    </SimpleScroll>
                </>
            )}
        </Modal>
    );
};

const Timeline = async (companyId,api) => {
    return dialogPromise((success, cancel) => (
        <TimeLineDailog success={success} cancel={cancel} companyId={companyId} api={api} />
    ));
};

const SmartTimeLine = ({ data }) => {
    return data.map((item, index) => (
        <div key={index}>
            <DateContent>{moment(item.date.substr(0, 10)).format("ddd, MMM Do")}</DateContent>
            <Spacer bottom={2} />
            <RowTimeline data={item} date={item.date} />
            <Spacer bottom={5} />
        </div>
    ));
};

export default DefaultAutoUsers;
