import React, { useEffect, useState } from "react";
import { Button, CustomDropdown, Spacer, Title } from "@dspworkplace/ui";
import { useForm } from "react-hook-form";
import { getCompanyId, useAuth } from "../../../../Auth";
import { Content } from "../../../../components/Menus/Navigator";

const MaintenanceTaskTracker = () => {
    const { api } = useAuth();
    const { register, handleSubmit, errors, reset, watch } = useForm();
    const [station, setStation] = useState([]);

    const onSubmit = (data) => {
        let params = {
            actions: {
                response: {
                    Vehicle: {
                        custom: {
                            functionName: "getMaintenanceTaskTrackerReport",
                            get: "file",
                            criteria: {
                                stationCode: data.station,
                                companyId: getCompanyId(),
                            },
                        },
                    },
                },
            },
        };

        api.post("/api/lazy/manage/data", params, {
            cache: {
                ignoreCache: true,
            },
        }).then(
            (response) => {
                const element = document.createElement("a");
                element.href = response.data.data.file;
                document.body.appendChild(element);
                element.click();
            },
            (error) => {}
        );
    };

    useEffect(() => {
        getStation();

        async function getStation() {
            const params = {
                actions: {
                    response: {
                        Station: {
                            findBy: {
                                criteria: {
                                    company: getCompanyId(),
                                },
                                get: "Station",
                                includes: {
                                    0: "id",
                                    1: "name",
                                    2: "code",
                                },
                                excludes: [
                                    "company",
                                    "skill",
                                    "users",
                                    "parent",
                                    "childrens",
                                    "driverRoutes",
                                    "payRate",
                                    "skillRates",
                                    "shifts",
                                    "drivers",
                                ],
                            },
                        },
                    },
                },
            };
            await api
                .post("/api/lazy/manage/data", params, {
                    cache: {
                        ignoreCache: true,
                    },
                })
                .then(
                    (response) => {
                        if (response.data.data.Station) {
                            setStation(
                                response.data.data.Station.map((s) => {
                                    return { name: s.name, value: s.code };
                                })
                            );
                        }
                    },
                    (error) => {}
                );
        }
    }, []);

    return (
        <Content className="scrollable content">
            <Spacer bottom={10} id="company-information">
                <Title>Maintenance TaskTracker Report</Title>
                <Spacer bottom={5} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomDropdown
                        name="station"
                        label="Stations"
                        ref={register}
                        placeholder="All"
                        multiple={true}
                        options={station}
                    />
                    <Spacer bottom={5} />
                    <Button type="primary">Export</Button>
                </form>
            </Spacer>
        </Content>
    );
};
export default MaintenanceTaskTracker;
