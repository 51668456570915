import axios from "axios";
import { getCompanyId, getUserId } from "../../../../Auth";
import { engine } from "../../../../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;

const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c) {
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if (API.current)
            return canceler();
        else
            return false;
    },
    fetchWaveTimes(params = {}) {
        return API.call({
            actions: {
                response: {
                    WaveTime: {
                        custom: {
                            functionName: 'fetchWave',
                            get: 'waveTime',
                            criteria: {
                                companyId: getCompanyId(),
                                stationId: params.station
                            }
                        }
                    }
                }
            }
        });
    },
    getWaveTimesMap(idWaveMap) {
        return API.call({
            actions: {
                response: {
                    WaveMap: {
                        findOneBy: {
                            get: "WaveMap",
                            criteria: {
                                company: getCompanyId(),
                                id: idWaveMap
                            },
                            includes: {
                                "1": "name",
                                "2": "waveTime"
                            }
                        }
                    }
                }
            }
        })
    },
    addMapLocation(params = {}, idWaveMap) {
        params.company = getCompanyId();
        params.user = getUserId();

        //edit
        if (idWaveMap) {
            return API.call({
                "actions": {
                    "response": {
                        "WaveTime": {
                            "custom": {
                                "functionName": "updateWaveTime",
                                "get": "waveTime",
                                "criteria": {
                                    "id": idWaveMap,
                                    "name": params.name,
                                    "waveTime": params.waveTime,
                                    "company": params.company,
                                    "station": params.station
                                }
                            }
                        }
                    }
                }
            });
        } else {
            return API.call({
                "actions": {
                    "response": {
                        "WaveTime": {
                            "custom": {
                                "functionName": "addWaveTime",
                                "get": "WaveTime",
                                "criteria": {
                                    "name": params.name,
                                    "waveTime": params.waveTime,
                                    "stationId": params.station,
                                    "companyId": params.company
                                }
                            }
                        }
                    }
                }
            })
        }

    },
    addMultipleMapLocation(waveTime = [], station) {
        return API.call({
            actions: {
                "response": {
                    "WaveMap": {
                        "custom": {
                            "functionName": "addMultipleStagingLocation",
                            "criteria": {
                                waveTime: waveTime,
                                station: station.id,
                            },
                            "get": "addMultipleStagingLocation",
                            "includes": {
                                "0": "id",
                                "1": "name",
                                "2": "waveTime",
                            }
                        },
                    }
                }
            }
        })
    },
    removeStationMap: (id) => {
        return API.call({
            actions: {
                delete: {
                    WaveTime: {
                        WaveTime_1: {
                            findOneBy: {
                                id: id
                            },
                            isHardDelete: true
                        }
                    }
                }
            }
        })
    },
    massDeleteRecord: (ids) => {
        return API.call({
            ids: ids,
            isHardDelete: true
        }, '/api/massDelete/WaveTime')
    }
}

export default API;