import React from "react";
import styled from "styled-components";
import { Spacer } from "@dspworkplace/ui";

const PasswordValidationContainer = styled.div`
    border: 2px solid #f7931e;
    border-radius: 10px;
    ul {
        list-style: none;
        padding-left: 40px;
        > li {
            text-indent: -26px;
            &.green {
                color: #007500;
            }
            &.default {
                color: #516f90;
            }
        }
    }
`;
const GreenValidateCheckMark = styled.div`
    &.checkMark {
        display: inline-block;
        width: 24px;
        height: 24px;
        position: relative;
        box-sizing: border-box;
    }
    &.checkMark::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 5px;
        border: solid #007500;
        border-width: 0 0 2px 2px;
        transform: rotate(-45deg);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        margin-top: 6px;
    }
`;
const CrossValidationCheckMark = styled.div`
    &.cross {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
    }

    &.cross::before,
    &.cross::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        width: 2px;
        height: 50%;
        background-color: #ff0000;
        transform-origin: center;
    }

    &.cross::before {
        transform: rotate(45deg);
    }

    &.cross::after {
        transform: rotate(-45deg);
    }
`;

const PasswordErrorValidate = ({ validation, showValidateUsernameAndLast6Password = false, confirmPassword = false }) => {
    return (
        <>
            <Spacer top={4} />
            <PasswordValidationContainer>
                <ul>
                    <li className={validation.hasLowerUpperCaseAndNumberSpecialChar != false ? "default" : "green"}>
                        {validation.hasLowerUpperCaseAndNumberSpecialChar == false ? (
                            <GreenValidateCheckMark className={"checkMark"} />
                        ) : (
                            validation.hasLowerUpperCaseAndNumberSpecialChar == true && (
                                <CrossValidationCheckMark className={"cross"} />
                            )
                        )}
                        Include both lowercase and uppercase letters, numbers, and symbols
                    </li>
                    <li className={validation.hasLength != false ? "default" : "green"}>
                        {validation.hasLength == false ? (
                            <GreenValidateCheckMark className={"checkMark"} />
                        ) : (
                            validation.hasLength == true && <CrossValidationCheckMark className={"cross"} />
                        )}
                        Be at least 8 characters long
                    </li>
                    <li className={validation.hasRepeatedOrSequential != false ? "default" : "green"}>
                        {validation.hasRepeatedOrSequential == false ? (
                            <GreenValidateCheckMark className={"checkMark"} />
                        ) : (
                            validation.hasRepeatedOrSequential == true && (
                                <CrossValidationCheckMark className={"cross"} />
                            )
                        )}
                        Not have 3 consecutive or identical characters
                    </li>
                    {showValidateUsernameAndLast6Password && (
                        <>
                            <li className={validation.hasLast6UsedPassword != false ? "default" : "green"}>
                                {validation.hasLast6UsedPassword == false ? (
                                    <GreenValidateCheckMark className={"checkMark"} />
                                ) : (
                                    validation.hasLast6UsedPassword == true && (
                                        <CrossValidationCheckMark className={"cross"} />
                                    )
                                )}
                                Cannot match any of your last 6 passwords
                            </li>
                            <li className={validation.hasUsername != false ? "default" : "green"}>
                                {validation.hasUsername == false ? (
                                    <GreenValidateCheckMark className={"checkMark"} />
                                ) : (
                                    validation.hasUsername == true && <CrossValidationCheckMark className={"cross"} />
                                )}
                                Cannot contain parts of your username
                            </li>
                        </>
                    )}
                    {confirmPassword && (
                        <>
                            <li className={validation.hasConfirmPassword != false ? "default" : "green"}>
                                {validation.hasConfirmPassword == false ? (
                                    <GreenValidateCheckMark className={"checkMark"} />
                                ) : (
                                    validation.hasConfirmPassword == true && (
                                        <CrossValidationCheckMark className={"cross"} />
                                    )
                                )}
                                Password does not match confirmed password
                            </li>
                        </>
                    )}
                </ul>
            </PasswordValidationContainer>
        </>
    );
};

export { PasswordErrorValidate };
