import React, { useEffect, useReducer } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon, Spacer, Text, Title, Button, Theme, CheckBox } from "@dspworkplace/ui";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../../components/Table";
import { Tag } from "../../../../components/UI";
import Tooltip from '../../../../components/Tooltip';
import { STATUS } from "../../../Announcements/Sections/Templates/helper";
import Empty from '../../../../components/Empty';
import Loading, { LoadingWrapper } from "../../../../components/Loading";
import { useLocation } from "react-router-dom";
import API from "./api";
import { initialState, reducer } from "./reducer";
import { openWaveMapForm, openWaveTimesArchiveForm } from './form';

const ToolTipContainer = styled.div`
    display:grid;
    grid-template-columns: repeat(${props => props.gridQty ? props.gridQty : 6}, minmax(40px, auto) ) ;
    gap: 10px;
`;

const App = () => {
    const useLocationState = useLocation().state;
    const [state, dispatch] = useReducer(reducer, {}, initialState);
    const [isChecked, setIsChecked] = React.useState([]);
    const [isCheckAll, setIsCheckAll] = React.useState(false);


    useEffect(() => {
        API.fetchWaveTimes({ station: useLocationState.station.id }).then(
            response => {
                dispatch({ type: 'addData', payload: { data: response.data.data.waveTime.waveTimes } })
            },
            error => console.log(error)
        );
    }, [state.matching, state.filterLookups, state.refreshCount])

    return (
        <div>
            <WaveList
                state={state}
                dispatch={dispatch}
                station={useLocationState.station}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                isCheckAll={isCheckAll}
                setIsCheckAll={setIsCheckAll}
            />
        </div>
    );
}

const WaveList = ({ state, dispatch, station, isChecked, setIsChecked, isCheckAll, setIsCheckAll }) => {
    const { data, status } = state;
    const [loading, setLoading] = React.useState({});
    const [disabled, setDisabled] = React.useState(false)

    const handleDelete = () => {
        const names = data.filter(item => isChecked.includes(item.id)).map(item => item.name);
        const updatedLoading = {};
        isChecked.forEach(item => {
            updatedLoading[item] = true;
        });
        if (isChecked && isChecked.length > 0) {
            openWaveTimesArchiveForm(names).then(
                resolve => {
                    if (resolve) {
                        setLoading({ ...updatedLoading })
                        setDisabled(true)
                        API.massDeleteRecord(isChecked).then(
                            resolve => {
                                if (resolve) {
                                    dispatch({ type: 'removeMultipleData', payload: { id: isChecked } })
                                    setIsChecked([])
                                    setLoading({})
                                    setDisabled(false)
                                }
                            },
                            error => {
                                alert({ title: `${names}could not be deleted`, text: 'try again or contact support' });
                                setLoading({})
                            }
                        )
                    }
                }
            )
        }
    }

    const handleSelectAll = e => {
        if (isCheckAll) {
            const ele = document.getElementsByName('selectItems');
            for (let i = 0; i < ele.length; i++) {
                if (ele && ele[i]?.type == 'checkbox')
                    ele[i].checked = false;
            }
            setIsChecked([]);
        } else {
            setIsChecked(data.map(li => li.id));
            const ele = document.getElementsByName('selectAllItems');
            for (let i = 0; i < ele.length; i++) {
                if (ele && ele[i]?.type == 'checkbox')
                    ele[i].checked = true;
            }
        }
        setIsCheckAll(!isCheckAll);
    };

    useEffect(() => {
        if (isChecked.length < data.length || isChecked.length === 0) {
            const ele = document.getElementsByName('selectItems');
            if (ele && ele[0]?.type === 'checkbox') {
                ele[0].checked = false;
            }
        }
    }, [isChecked])


    return (
        <>
            <Spacer top={5} />
            <Title>Wave Times</Title>
            <Spacer top={1} />
            <Text>Manage your Wave Name and Wave Time in one place.</Text>
            <Spacer top={4} />
            <Table width={'580px'}>
                <TableHeader
                    headers={[
                        {
                            label: <CheckBox
                                name={"selectItems"}
                                id={"selectItems"}
                                title={""}
                                options={[{ value: true, label: "" }]}
                                onClick={handleSelectAll}
                            />, width: '10%', style: { fontSize: "9px" }
                        },
                        { label: 'Wave Name', width: '30%' },
                        { label: 'Wave Time', width: '45%' },
                        { label: '', width: '15%' },
                    ]}
                />
                {
                    status === STATUS.PENDING
                        ?
                        <Loading style={{ height: 80, margin: '50px auto', display: 'block' }} />
                        :
                        <WaveTimeRowData data={data} dispatch={dispatch} station={station} isChecked={isChecked} setIsChecked={setIsChecked} setIsCheckAll={setIsCheckAll} loading={loading} />
                }
                <TableFooter sticky={true} style={{ justifyContent: 'flex-end' }}>
                    <Spacer left={1} right={6}>
                        <Button
                            Type={'button'}
                            type={'primary'}
                            onClick={() => {
                                openWaveMapForm({ station }).then(
                                    resolve => {
                                        if (resolve) {
                                            API.fetchWaveTimes({ station: station.id }).then(
                                                response => {
                                                    dispatch({ type: 'addData', payload: { data: response.data.data.waveTime.waveTimes } })
                                                },
                                                error => console.log(error)
                                            );
                                        }
                                    },
                                    error => { console.log('error', error) }
                                )
                            }}
                        >
                            Add New Wave Time
                        </Button>
                    </Spacer>
                    <Spacer right={1}>
                        <div onClick={handleDelete} style={{
                            cursor: "pointer",
                            pointerEvents: disabled ? 'none' : '',
                            opacity: disabled ? '0.5' : ''
                        }}>
                            <Icon.Trash color={Theme.colors.error.border} />
                        </div>
                    </Spacer>
                </TableFooter>

            </Table>
        </>
    )
}

const blink = keyframes`
    0% {
        background-color: ${Theme.colors.info.bg};
        box-shadow: 0 0 5px ${Theme.colors.info.shadow};
    }
    35% {
        background-color: ${Theme.colors.info.bg};
        box-shadow: 0 0 5px ${Theme.colors.info.shadow};
    }
    100% {
        background-color: ${Theme.colors.neutrals.white};
        box-shadow: 0 0 0 transparent;
    }
`;

const TableRowBlink = styled(TableRow)`
    &.blink {
        animation: ${blink} 3s;
    }
`

const WaveTimeRowData = ({ data, dispatch, station, isChecked, setIsChecked, setIsCheckAll, loading }) => {
    const [rowInfo, setRowInfo] = React.useState({ delete: false })

    useEffect(() => {
        const ele = document.getElementsByName('selectAllItems');
        if (isChecked && isChecked.length === 0) {
            for (let i = 0; i < ele.length; i++) {
                if (ele[i]?.type === 'checkbox')
                    ele[i].checked = false;
            }
        } else if (data.length === isChecked.length) {
            const ele = document.getElementsByName('selectItems');
            if (ele && ele[0]?.type == 'checkbox')
                ele[0].checked = true;
            setIsCheckAll(true);
        }
    }, [isChecked])

    if (data.length < 1) {
        return (
            <TableData width='100%' style={{ margin: '0 auto', display: 'block' }} >
                <Empty />
            </TableData>
        )
    }

    const handleCheckbox = (e, k) => {
        const { checked } = e.target;
        setIsChecked([...isChecked, k]);
        if (!checked) {
            setIsChecked(isChecked.filter(item => item !== k));
            setIsCheckAll(false)
        }
        setIsCheckAll(false)
    }

    return (
        <>
            {data.map((t, k) => {
                const maxShowUp = 2;
                const firsts = t.waveTime ? t.waveTime.slice(0, maxShowUp) : null;
                const rest = t.waveTime.length > maxShowUp ? t.waveTime.slice((t.waveTime.length - maxShowUp) * -1) : [];

                return (
                    <TableRowBlink key={k} style={{ flexWrap: 'wrap', position: 'relative' }} className={t.isNew ? 'blink' : ''}>
                        {(rowInfo.delete === k || loading[t.id]) &&
                            <LoadingWrapper style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', zIndex: 1 }}>
                                <Loading style={{ height: 20 }} />
                            </LoadingWrapper>
                        }
                        <TableData width='10%'>
                            <div style={{ fontSize: "4px" }}>
                                <CheckBox
                                    name={"selectAllItems"}
                                    id={"selectAllItems"}
                                    title={""}
                                    onClick={(e) => { handleCheckbox(e, t.id) }}
                                    options={[{ value: true, label: "" }]}
                                />
                            </div>
                        </TableData>
                        <TableData width='30%'>
                            {t.name}
                        </TableData>
                        <TableData width='45%'>
                            {firsts.map((f, k) => <Tag key={k} style={{ marginRight: 4 }} >{f}</Tag>)}
                            <Tooltip
                                horizontalPosition={'left'}
                                verticalPosition={'center'}
                                content={
                                    <ToolTipContainer gridQty={rest.length >= 6 ? 6 : rest.length}>
                                        {rest.map((r, k) => <Tag key={k}>{r}</Tag>)}
                                    </ToolTipContainer>
                                }
                            >
                                {rest.length > 0 && <Tag>+{rest.length} More</Tag>}
                            </Tooltip>
                        </TableData>
                        <TableData width='15%'>
                            <Spacer inline right={3}>
                                <Icon.Edit
                                    size='20px'
                                    color='#7C98B6'
                                    style={{ position: 'relative', top: '3px' }}
                                    onClick={() => {
                                        openWaveMapForm({ station, waveMapObj: t }).then(
                                            resolve => {
                                                if (resolve) {
                                                    const waveTime = resolve.data.data.waveTime;
                                                    dispatch({ type: 'editData', payload: { data: waveTime } })
                                                }
                                            },
                                            error => { console.log('error', error) }
                                        )
                                    }}
                                />
                            </Spacer>
                            <Icon.Times
                                size='20px'
                                color='#7C98B6'
                                onClick={() => {
                                    openWaveTimesArchiveForm(t.name).then(
                                        resolve => {
                                            if (resolve) {
                                                setRowInfo({ ...rowInfo, delete: k })
                                                API.removeStationMap(t.id).then(
                                                    resolve => {
                                                        if (resolve) {
                                                            dispatch({ type: 'removeMultipleData', payload: { id: [t.id] } })
                                                            setRowInfo({ ...rowInfo, delete: false })
                                                            setIsChecked([])
                                                        }
                                                    },
                                                    error => {
                                                        alert({ title: `${t.name} could not be deleted`, text: 'try again or contact support' });
                                                    }
                                                )
                                            }
                                        }
                                    )
                                }} />
                        </TableData>
                    </TableRowBlink>
                )
            })}
        </>
    )
}

export default App;