import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Alert,
    Button,
    ButtonDropdown,
    CheckBox,
    DropUploader,
    Icon,
    Input,
    RadioBox,
    Spacer,
    SubTitle,
    Text,
    Theme,
    ThirdTitle,
    Toggle
} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import moment from "moment";
import {engine} from "../../Utilities/index";
import {getCompanyId, getToken, getUserId, uploadToMedia, useAuth} from "../../Auth";
import {portalPromise} from "../../components/Dialog";
import {confirm} from '../../components/Confirm';
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import {useSelectedStation} from "../../components/StationSelector";
import {Table, TableData, TableHeader, TableRow} from "../../components/Table";
import toast from "../../components/Toasts/toast";
import {Label, Link, Question} from "../../components/UI";
import Tooltip from "../../components/Tooltip";
import RADriverNotification from './raDriverNotification'
import {fetchAllSettings, getVanAutoAssignmentDateData} from "../Vehicles/VanAutoAssignment/api";
import {FilterWeekDate} from "../../hooks/useGlobalData";
import useReportSourceDocumentsApi from "../../api/reportSourceDocuments";
import LoadOutStore from "./context";
import apiFactory from "../../api/factory";
import API from "./api";
import ExcelPicklist1 from '../../assets/excelPicklist1.png'
import ExcelPicklist2 from '../../assets/excelPicklist2.png'
import ExcelPicklist3 from '../../assets/excelPicklist3.png'

const LoadOutTools = () => {
    const [state, dispatch] = useContext(LoadOutStore);
    const { api } = useAuth();

    // RouteAssist

    const [vanAutoAssignmentSettings, setVanAutoAssignmentSettings] = useState();
    const tools = useMemo(() => {
        let tools = [
            {
                text: <>1. RouteAssist™</>,
                fn() {
                    showRouteAssistDialog()
                }
            },
            {
                text: <>2. Import Staging Locations/Picklist</>,
                fn() {
                    showPicklistImport(vanAutoAssignmentSettings, state.waveData.drivers, state, dispatch, api)
                }
            },
        ];

        if (vanAutoAssignmentSettings?.setting?.isEnable)
            tools.push({
                text: <>3. Van Auto Assignment</>,
                fn() {
                    showVanAutoAssignmentDialog(vanAutoAssignmentSettings, api, state, dispatch)
                }
            });

        tools.push({
            text: <>{tools.length + 1}. Send Assignment Notification</>,
            fn() {
                showSendAssignmentNotificationDialog(state.waveData.drivers)
            }
        })

        return tools
    }, [state.waveData.drivers, vanAutoAssignmentSettings]);

    useEffect(() => {
        if (state.waveData.station)
            fetchAllSettings(state.waveData.station)
                .then(setVanAutoAssignmentSettings)
    }, [state.waveData.station])

    return <ButtonDropdown
        text='Tools'
        divider={false}
        options={tools}
        visibleOptionsQty={10}
    />
}

const showRouteAssistDialog = () => {
    return portalPromise((success, cancel) =>
        <CortexAssistant
            close={cancel}
        />
    );
}

const CortexAssistant = ({ close }) => {
    const getBookmarkFuncStr = (versionNo) => {
        const url = new URL(`${window.location.protocol}//${window.location.host}`);
        url.pathname = versionNo && versionNo.charAt(0) === "3" ? "ra_v3.js" : "ra_v2.js";

        return `() => {
            window.__dspw_ra = ${JSON.stringify({
                token: getToken(),
                companyId: getCompanyId(),
                userId: getUserId(),
                host: encodeURIComponent(`${window.location.protocol}//${window.location.host}`),
            })};
            const tag = document.createElement("script");
            tag.src = "${url.toString()}";
            document.head.appendChild(tag);
        }`;
    };

    return (
        <>
            <Modal removeHeader={true} visible={true} setVisible={close}>
                <div style={{ textAlign: "center" }}>
                    <div
                        style={{
                            // background: "#232F3E",
                            display: "inline-block",
                            verticalAlign: "top",
                            padding: "0 16px",
                            height: 54,
                            lineHeight: 1.2,
                            marginTop: 12,
                            color: '#008296'
                        }}
                    >
                        COSAM1, <br/>CV1, <br/>CX100...
                    </div>
                    <Spacer all={5} inline style={{ verticalAlign: "top" }}>
                        <Icon.ArrowRight
                            size="40px"
                            color={Theme.colors.neutrals.silver}
                        />
                    </Spacer>
                    <Icon.DspwNetwork size="80px" color={Theme.colors.primary} />
                    <Spacer top={3} />
                    <SubTitle>
                        Import route codes <br />
                        with a single click
                    </SubTitle>
                    <Spacer top={3} />
                    <Alert.Info
                        content={`New version 3: Download even more security documents and import route codes and package information with greater accuracy.`}
                    />
                    <Spacer top={3} />
                    <Text>
                        Drag this button to your bookmark bar, open Operations &gt;
                        Delivery page, select the desired station and click the bookmark.
                    </Text>

                    <Spacer top={3} />
                    <Button
                        type="primary"
                        as="a"
                        href={`javascript:(${getBookmarkFuncStr("3")})()`}
                        style={{ lineHeight: 2.5 }}
                        onDragEnd={e => {
                            // outside screen
                            if (e.clientX < 0 || e.clientY < 0) {
                                close();
                            }
                        }}
                    >
                        <Icon.DspwNetwork
                            size="20px"
                            color={Theme.colors.neutrals.white}
                            style={{ verticalAlign: "text-top" }}
                        />
                        <Spacer right={2} inline />
                        RouteAssist™ <sup>3</sup>
                    </Button>

                    <Spacer top={5} />
                    <Button type="cancel" onClick={close} size="small">
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};


const showPicklistImport = async (vanAutoAssignmentSettings, drivers, state, dispatch, api) => {
    const unassigned = drivers.filter(d => !d.routes.length).length;
    let proceed = true;

    if (unassigned)
        proceed = await confirm({
            icon: <Icon.Info/>,
            title: 'You have unassigned drivers',
            text: `Currently <strong>${unassigned}</strong> driver${unassigned === 1 ? '' : 's'} are not assigned to a Route Code. This might create unassigned routes. Try running RouteAssist™ before importing your picklist. Do you want to proceed anyway?`,
        });

    if (proceed)
        return portalPromise((success, cancel) =>
            <PicklistImport
                close={cancel}
                showRunVanAutoAssignment={vanAutoAssignmentSettings?.setting?.isEnable}
                state={state}
                dispatch = {dispatch}
                api = {api}
                setting={vanAutoAssignmentSettings}
            />
        )
}

const warnPicklistUploadInProgress = e => {
    e.returnValue = 'If you leave while picklist is being uploaded, the import will be cancelled.';
    return e.returnValue
}

const PicklistImport = ({ close, showRunVanAutoAssignment, dispatch, state, api, setting }) => {
    const [station, set] = useSelectedStation();
    const [picklist, setPicklist] = useState();
    const [seeFullImport, setSeeFullImport] = useState();
    const {register, handleSubmit} = useForm();
    const {create: createDocuments, setMetadata} = useReportSourceDocumentsApi();
    const [date, setDate] = FilterWeekDate();

    const doUpload = files => {
        let formData = new FormData();
        formData.append('type', 'Picklist');
        formData.append('entityId', 0);
        Array.from(files)
            .forEach(file => formData.append('file[]', file));

        return uploadToMedia(formData).catch(err => console.log(err));
    }

    const openImportSpreedHelper = () => {
        return portalPromise((success, cancel)=>{
            return <ImportSpreedHelper
                success={success}
                cancel={cancel}
            />
        })
    }

    const ImportSpreedHelper = ({success,cancel}) =>(
        <Modal
            width={'540px'}
            visible={true}
            title={'Using the Picklist Map Import Tool'}
            closeOnOverlay={true}
            setVisible={cancel}
        >
            <Spacer top={2} />
                <Text>To quickly import your staging locations and/or Wave times, use the Amazon morning spreadsheet sent via Chat to arrange your morning data in the required order: Route Code, Staging Location, Wave Time, Total Packages, and Commercial Packages. The import tool only requires the first 2 columns, but you can import all 5 if you have them.</Text>
            <Spacer top={3} />
            <ThirdTitle>Example: </ThirdTitle>
            <img style={{ width: '100%' }} src={ExcelPicklist1} />
            <Spacer top={3} />
            <Text>Next, select only the data you wish to import -- DO NOT include the header row. Then copy the data:</Text>
            <Spacer top={3} />
            <img style={{ width: '100%' }} src={ExcelPicklist2} />
            <Spacer top={2} />
            <Text>Once you have copied the data, proceed to the Staging location import and paste your copied data into the text box. It should look something like this:</Text>
            <Spacer top={3} />
            <img style={{ width: '100%' }} src={ExcelPicklist3} />
            <Spacer top={2} />
            <Text>If your data looks correct, then click the Save button, and wait a few minutes to allow for the data to be processed. Once complete you should see your route information on the Load out screen and you can send the notification to your drivers!</Text>
            <Spacer top={2} />
        </Modal>
    )

    const ImportPicklistSheet = ({success,cancel,station}) => {
        const [temp,setTemp] = useState({data:[],status:'PENDING'});
        const [error,setError] = useState({status:false});
        const loginUser = localStorage.getItem("userId");

        const handlePaste = async(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData('text');
            let textRows = text.split('\n');
            let errorStatus = false;
            if (textRows[0].split('\t').length < 2 ||  textRows[0].split('\t').length > 5) {
                errorStatus = true;
                setError({status:true,message:"Please add min 2 and max 4 column!!!"});
                setTemp({...temp,data:[],status:'PENDING'})
                return false;
            }
            var filtered = textRows.filter(function (el) {
                return el != "";
            });
            const table = filtered.map((h, k) => {
                const dataRow = h.split('\t');
                if (dataRow[3] && isNaN(parseInt(dataRow[3]))) {
                    errorStatus = true;
                    setError({ status: true, message: "Please check data is not properly formatted" });
                }
                if (dataRow[4] && isNaN(parseInt(dataRow[4]))) {
                    errorStatus = true;
                    setError({ status: true, message: "Please check data is not properly formatted" });
                }
                return {
                    routeCode: dataRow[0],
                    stagingLocation: dataRow[1],
                    waveTime: dataRow[2] ? dataRow[2].replace('\r', '') : null,
                    totalPackages: (dataRow[3]) ? dataRow[3] : 0,
                    commercialPackages: (dataRow[4]) ? dataRow[4] : 0,
                }
            })
            if(errorStatus === true){
                setTemp({...temp,data:[],status:'PENDING'})
                return false;
            }
            setError({status:false});
            setTemp({...temp,data:table,status:'PROCESSED'})
         }

        return (
            <Modal
                width={'640px'}
                visible={true}
                title={'Import Staging Locations'}
                closeOnOverlay={false}
                setVisible={cancel}
            >

            <div>
             <Input
                 onPaste={handlePaste}
                 style={{width:'590px'}}
                 placeholder={'Copy all Route Code,Staging Location,Wave Time,Total Packages and Commercial Packages and paste here.'}
                 error={error.status && error.message && error.message}
             />
             <Spacer top={3} bottom={2}>
                 <span style={{cursor:'pointer',display:'block'}} onClick={()=>openImportSpreedHelper()}>
                     <ThirdTitle style={{display: 'inline'}}>Need Help </ThirdTitle>
                     <Question>?</Question>
                 </span>
             </Spacer>

             {temp.status === 'CREATING' &&
                <Loading style={{height: 80,margin:'50px auto',display:'block'}} />
             }

             {temp.status === 'PROCESSED' &&
             <Table width={'100%'}>
                 <TableHeader
                     headers={[
                         {label: 'Route Code', width: '20%'},
                         {label: 'Staging', width: '20%'},
                         {label: 'Wave Time', width: '20%'},
                         {label: 'Total Packages', width: '20%'},
                         {label: 'Commercial Packages', width: '20%'},
                     ]}
                 />
                 {temp.data.map((t,k)=>{
                    return (
                        <TableRow key={k} style={{flexWrap: 'wrap',position:'relative'}} >
                            <TableData width='20%'>
                                {t.routeCode ? t.routeCode : '-'}
                            </TableData>
                            <TableData width='20%'>
                                {t.stagingLocation ? t.stagingLocation : '-'}
                            </TableData>
                            <TableData width='20%'>
                                {t.waveTime ? t.waveTime : '-'}
                            </TableData>
                            <TableData width='20%'>
                                {t.totalPackages ? t.totalPackages : '-'}
                            </TableData>
                            <TableData width='20%'>
                                {t.commercialPackages ? t.commercialPackages : '-'}
                            </TableData>
                        </TableRow>
                    )
                 })}
             </Table>
             }

             {temp.status === 'PROCESSED' &&
             <Spacer top={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                 <Button
                     type={'primary'}
                     onClick={()=>{
                         setTemp({...temp,status:'CREATING'})
                         API.addImportPicklistData(api,temp.data,station,getCompanyId(),date,loginUser).then(
                             resolve => {
                                 success(true);
                                 toast({
                                    type: 'success',
                                    title: 'Your picklist is being imported in the background.',
                                    content: "You will be notified when it is finished",
                                    useClose: true
                                });
                             },
                             error => {
                                 setTemp({...temp,status:'PENDING'})
                             }
                         )
                     }}
                 >
                     Save Data
                 </Button>
             </Spacer>
             }
         </div>
            </Modal>
        )
    }

    const openImportPicklist = ({station}) => {
        return portalPromise((success, cancel)=>{
            return <ImportPicklistSheet
                success={success}
                cancel={cancel}
                station={station}
            />
        })
    }

    const onSubmit = data => {
        toast({
            type: 'info',
            title: 'Your file is being uploaded...'
        });

        window.addEventListener('beforeunload', warnPicklistUploadInProgress)

        doUpload(data.picklist)
            .then(response => {
                if (!response?.data) {
                    setPicklist(undefined);
                    throw new Error("Upload failed. Please try again later.");
                }

                return Promise.all([
                    response.data[0],
                    createDocuments([{
                        company: getCompanyId(),
                        station: station,
                        url: response.data[0].path,
                        name: response.data[0].path.split('/').pop(),
                        category: 'Picklist',
                        source: 'manual'
                    }])
                ]);
            }).then(([media, response]) => {
                if (!response?.data?.created)
                    throw new Error('Upload failed. Please try again later.');

                console.log(media)

                Object.values(response.data.created).map(d =>
                    setMetadata({
                        document: d.entityId,
                        key: 'media',
                        value: media.id
                    })
                );

                if(date)
                    Object.values(response.data.created).map(d =>
                        setMetadata({
                            document: d.entityId,
                            key: 'documentUploadLoadoutDate',
                            value: date
                        })
                    );

                toast({
                    type: 'success',
                    title: 'Your picklist is being imported in the background.',
                    content: "You will be notified when it is finished",
                    useClose: true
                })
            }).catch(e => {
                setPicklist(undefined);
                console.log(e);
                toast({
                    type: 'error',
                    title: e.message,
                    useClose: true
                })
            }).finally(() => {
                window.removeEventListener('beforeunload', warnPicklistUploadInProgress);
            });

        close();
    }

    return <Modal
        visible={true}
        setVisible={close}
        title={'Picklist or Staging Locations Import Tools'}
        width={'616px'}
        closeOnOverlay={false}
    >
        {picklist ? (
            typeof picklist === 'string' ? (
                <div style={{ textAlign: 'center' }}>
                    <Loading style={{ width: 40 }}/>
                    <Spacer top={3}/>
                    <ThirdTitle>{ picklist }</ThirdTitle>
                </div>
            ) : (
                <div>
                    <Spacer all={5} style={{ textAlign: 'center' }}>
                        <Icon.CheckNarrow color={Theme.colors.success.text}/>
                    </Spacer>
                    <Text>
                        Picklist successfully imported <strong>{ picklist.length } routes</strong> and it's package details.{' '}
                        <Link onClick={() => setSeeFullImport(!seeFullImport)}>{seeFullImport ? 'Hide results' : 'See full import result'}.</Link>
                    </Text>
                    {seeFullImport ? (
                        <Spacer top={3}>
                            <Table width="auto">
                                <TableHeader
                                    headers={[
                                        {label: 'Route Code', width: '100px'},
                                        {label: 'Staging Location', width: '128px'},
                                        {label: 'Total Packages', width: '108px'},
                                        {label: 'Commercial Packages', width: '160px'},
                                        {label: 'Details', width: '80px'},
                                    ]}
                                />
                                {picklist.sort((a, b) => a.routeCode.localeCompare(b.routeCode, undefined, {numeric: true})).map(p =>
                                    <TableRow key={p.routeCode}>
                                        <TableData width={'100px'}>{p.routeCode}</TableData>
                                        <TableData width={'128px'}>{p.stagingLocation}</TableData>
                                        <TableData width={'108px'}>{p.totalPackages}</TableData>
                                        <TableData width={'160px'}>{p.commercialPackages}</TableData>
                                        <TableData width={'80px'}>
                                            <Tooltip horizontalPosition={'right'} verticalPosition={'center'} content={(
                                                <Table>
                                                    <TableHeader headers={[
                                                        {label: `${ p.bagsAndOverflows.bags.length } bags` },
                                                        {label: `${ p.bagsAndOverflows.overflows.length } overflows` }
                                                    ]}/>
                                                    <TableRow>
                                                        <TableData>
                                                            <div>
                                                                { p.bagsAndOverflows.bags.map(b =>
                                                                    <Text key={b.Order}>{b.Order} - {b['Sort Zone']}, {b.Bag}, {b.Pkgs}</Text>
                                                                ) }
                                                            </div>
                                                        </TableData>
                                                        <TableData>
                                                            <div>
                                                                { p.bagsAndOverflows.overflows.map(o =>
                                                                    <Text key={o.Order}>{o.Order} - {o['Sort Zone']}, {o.Pkgs}</Text>
                                                                ) }
                                                            </div>
                                                        </TableData>
                                                    </TableRow>
                                                </Table>
                                            )}>
                                                <Icon.Plus size={'20px'} color={Theme.colors.info.border}/>
                                            </Tooltip>
                                        </TableData>
                                    </TableRow>
                                )}
                            </Table>
                        </Spacer>
                    ) : null}
                    {showRunVanAutoAssignment ? (
                        <Spacer top={5}>
                            <Button onClick={showVanAutoAssignmentDialog(setting, api, state, dispatch)}>Run Van Auto Assignment</Button>
                        </Spacer>
                    ) : null}
                </div>
            )
        ) : (
            <div className={"picklist-import"} style={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}>
                <form onSubmit={handleSubmit(onSubmit)} style={{flex: '1'}}>
                    <Text>Import the full daily picklist, or just copy and paste your routes assigned staging locations to easily add and share with your drivers.</Text>
                    <Spacer bottom={3}/>
                    <DropUploader
                        ref={register({
                            required: true
                        })}
                        name='picklist'
                        label={`Picklist File for ${JSON.parse(localStorage.getItem('stations')).find(s => s.id === station).code}`}
                        Required
                        accept={'application/pdf'}
                    />
                    <Spacer bottom={5}/>
                    <Button type='primary'>Upload and Import</Button>
                </form>
                <span style={{textTransform: 'uppercase'}}>or</span>
                <div style={{flex: '1', textAlign: 'center'}}>
                    <Button
                            Type={'button'}
                            type={'secondary'}
                            onClick={()=>{
                                openImportPicklist({station}).then( ()=>{
                                    close();
                                }
                                )
                            }}
                        >
                            Import Staging Locations
                    </Button>
                </div>
            </div>
        )}
    </Modal>
}


const showVanAutoAssignmentDialog = (settings, api, state, dispatch) => {
    return portalPromise((success, cancel) =>
        <VanAutoAssignment
            close={cancel}
            settings={settings}
            api={api}
            state={state}
            dispatch={dispatch}
        />
    );
}

const showVanAutoAssignmentDialog2 = (settings, api, state, dispatch, doNotVanAssignDrivers) => {
    return portalPromise((success, cancel) =>
        <VanAutoAssignment2
            close={cancel}
            settings={settings}
            api={api}
            state={state}
            dispatch={dispatch}
            doNotVanAssignDrivers={doNotVanAssignDrivers}
        />
    );
}

const VanAutoAssignment2 = ({ success, close, settings, api, state, dispatch, doNotVanAssignDrivers = false}) => {
    const [date, setDate] = FilterWeekDate();
    const [station, setStation] = useSelectedStation();
    const [driverRoute, setDriverRoute] = useState('driverRoute');
    const [driverRouteCount, setDriverRouteCount] = useState(0);
    const [driverRouteWithInvoiceCount, setDriverRouteWithInvoiceCount] = useState(0);
    const { register, getValues, errors} = useForm();
    const values = getValues();
    useEffect(() => {
        getVanAutoAssignmentDateData(settings.setting.id, date).then((res)=>{
            setDriverRouteCount(res.driverRouteCount);
            setDriverRouteWithInvoiceCount(res.driverRouteWithInvoiceCount);
        });
    }, [settings])

    const run = async () => {
        // if (new Date(date + ' 00:00:00') < new Date(new Date().toLocaleDateString("en-US") + ' 00:00:00')) {
            // const conf1 = await confirm({
            //     icon: true,
            //     text: "WARNING: You are about to run the Auto Van Assignment process for a past day (" + moment(date).format("MMM Do") + "), are you sure you want to do this?",
            // });

            // if (!conf1) {
            //     return;
            // }
            engine().post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        VanAutoAssignmentSetting: {
                            custom: {
                                functionName: "setVanAutoAssignment",
                                get: "success",
                                criteria: {
                                    date: moment(date).format('YYYY-MM-DD'),
                                    company: getCompanyId(),
                                    station: station,
                                    type: driverRoute,
                                    doNotVanAssignDrivers: doNotVanAssignDrivers
                                }
                            }
                        }
                    }
                }
            }).then(
                async(r) =>{ 
                    toast({
                        type: r?.data?.data?.success
                            ? 'success' : 'error',
                        content: r?.data?.data?.success
                            ? 'Van Auto Assignment is finished'
                            : "Sorry, something didn't work as expected, please contact support",
                        useClose: true
                    })

                    if (!station) station = state.waveData.station;

                    dispatch({
                        type: "SET_LOADING",
                        payload: true,
                    });

                    let data = await API.fetchFilterData(
                        {
                            station: station,
                            date: state.selectedDate,
                        },
                        api
                    );

                    if (!state.waveData.station && !station) {
                        if (data.stations && data.stations.length > 0) {
                            station = data.stations[0].value;
                        }
                    }

                    let wave = await API.fetchWaveData(
                        {
                            station: station,
                            date: state.selectedDate,
                        },
                        api
                    );

                    dispatch({
                        type: "SET_WAVE_DATA",
                        payload: wave,
                        callback(state) {
                            state.waveData.station = station;
                            state.filterData = data;
                        },
                    });

                    dispatch({
                        type: "SET_LOADING",
                        payload: false,
                    });
                }
            )
            close();
            toast({
                type: 'info',
                title: "Running van auto assignment",
                content: "The changes will appear shortly",
                timeout: 3000
            })
    }

    return <Modal
        title={'Van Auto Assignment'}
        visible={true}
        setVisible={close}
        closeOnOverlay={false}
        width={'660px'}
    >
        <Text>
            {(moment(new Date(date + ' 00:00:00')).format('YYYY-MM-DD') == moment(new Date(new Date().toLocaleDateString("en-US") + ' 00:00:00')).format('YYYY-MM-DD')) ?
             "You are running auto van assignment for today, " +  moment(date).format("MMM Do")  + ". As you have not yet run RouteAssist, how would you like to assign vehicles?"
             :"You are running auto van assignment for "+moment(date).format("MMM Do")+". As route codes are not assigned, how would you like to assign vehicles?"
            }
        </Text>
        <Spacer bottom={3}/>
        <RadioBox
            ref={register({ required: 'Required Shift Status' })}
            name='routeStatus'
            options={[
                { label: 'To all scheduled employees (' + driverRouteCount +')', value: 'driverRoute' },
                { label: 'To scheduled employees with an Invoice Type assigned (' + driverRouteWithInvoiceCount + ')', value: 'driverRouteWithInvoice' },
            ]}
            default={driverRoute}
            onChange={async (e) => {
                await setDriverRoute(e.target.value);
            }}
            valid={values.routeStatus && !errors.routeStatus}
            error={errors.routeStatus && errors.routeStatus.message}
        />
        <Spacer bottom={5}/>
        <Button onClick={() => { run() }} type='primary'>
            Run Van Auto Assignment
        </Button>
        <Spacer right={3} inline />
        <Button type='cancel' onClick={close}>
            Cancel
        </Button>
    </Modal>
}

const VanAutoAssignment = ({ close, settings, api, state, dispatch }) => {
    const [date, setDate] = FilterWeekDate();
    const [station, setStation] = useSelectedStation();
    const [doNotVanAssignDrivers, setDoNotVanAssignDrivers] = useState(false);
    
    const run = async () => {
        let hasTodayRouteCode = false;
        let isCurDate = false;
        let isPastDate = false;
        // if (moment(new Date(date + ' 00:00:00')).format('YYYY-MM-DD') == moment(new Date(new Date().toLocaleDateString("en-US") + ' 00:00:00')).format('YYYY-MM-DD')) {
            await engine().post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        VanAutoAssignmentSetting: {
                            custom: {
                                functionName: "getVanAutoAssignmentDateWithRouteCode",
                                get: "withRouteCode",
                                criteria: {
                                    date: moment(date).format('YYYY-MM-DD'),
                                    company: getCompanyId(),
                                    id: settings.setting.id,
                                }
                            }
                        }
                    }
                }
            }).then(
                async (r) => {
                    if (parseInt(r?.data?.data?.withRouteCode.driverRouteCount) > 0) {
                        hasTodayRouteCode = true;
                    }
                    isCurDate= r?.data?.data?.withRouteCode.isCurDate;
                    isPastDate= r?.data?.data?.withRouteCode.isPastDate;
                }
            )
        // }

        if ((hasTodayRouteCode == true && isCurDate == true) || (isPastDate == true)) {
            if (isPastDate == true){
                const conf1 = await confirm({
                    icon: true,
                    text: "WARNING: You are about to run the Auto Van Assignment process for a past day (" + moment(date).format("MMM Do") + "), are you sure you want to do this?",
                });

                if (!conf1) {
                    return;
                }
            }
            engine().post('/api/lazy/manage/data', {
                actions: {
                    response: {
                        VanAutoAssignmentSetting: {
                            custom: {
                                functionName: "setVanAutoAssignment",
                                get: "success",
                                criteria: {
                                    date: moment(date).format('YYYY-MM-DD'),
                                    company: getCompanyId(),
                                    station: station,
                                    type: 'withRouteCode',
                                    doNotVanAssignDrivers: doNotVanAssignDrivers
                                }
                            }
                        }
                    }
                }
            }).then(
                async(r) => {
                    toast({
                        type: r?.data?.data?.success
                            ? 'success' : 'error',
                        content: r?.data?.data?.success
                            ? 'Van Auto Assignment is finished'
                            : "Sorry, something didn't work as expected, please contact support",
                        useClose: true
                    })

                    if (!station) station = state.waveData.station;

                    dispatch({
                        type: "SET_LOADING",
                        payload: true,
                    });

                    let data = await API.fetchFilterData(
                        {
                            station: station,
                            date: state.selectedDate,
                        },
                        api
                    );

                    if (!state.waveData.station && !station) {
                        if (data.stations && data.stations.length > 0) {
                            station = data.stations[0].value;
                        }
                    }

                    let wave = await API.fetchWaveData(
                        {
                            station: station,
                            date: state.selectedDate,
                        },
                        api
                    );

                    dispatch({
                        type: "SET_WAVE_DATA",
                        payload: wave,
                        callback(state) {
                            state.waveData.station = station;
                            state.filterData = data;
                        },
                    });

                    dispatch({
                        type: "SET_LOADING",
                        payload: false,
                    });
                }
            )
            close();
            toast({
                type: 'info',
                title: "Running van auto assignment",
                content: "The changes will appear shortly",
                timeout: 3000
            })
        }else{
            close();
            let res = await showVanAutoAssignmentDialog2(settings, api, state, dispatch, doNotVanAssignDrivers);
        }
 
    }

    return <Modal
        title={'Van Auto Assignment'}
        visible={true}
        setVisible={close}
        closeOnOverlay={false}
        width={'660px'}
    >
        <Text>Automatically assign vehicles with smart rules to best use your resources. Your current settings:</Text>
        <Spacer bottom={3}/>
        <Table>
            <TableHeader
                compact={true}
                headers={[
                    {label: 'Package Rules', width: '24%'},
                    {label: 'Vehicles', width: '18%'},
                    {label: 'Van/Driver Affinity', width: '30%'},
                    {label: 'Conflict Rules', width: '24%'},
                ]}
            />
            <TableRow>
                <TableData width='24%' compact={true} style={{display: 'block', wordBreak: 'break-word'}}>
                    {settings.packageRules.map((r, i) => r.isEnable ? <p>{i + 1}. {r.label}</p> : null)}
                </TableData>
                <TableData width='18%' compact={true} style={{display: 'block', wordBreak: 'break-word'}}>
                    {settings.vehicleOrder.map((r, i) => r.isEnable ? <p>{i + 1}. {r.label}</p> : null)}
                </TableData>
                <TableData width='30%' compact={true} style={{display: 'block', wordBreak: 'break-word'}}>
                    {settings.driverAffinity.filter(r => r.isEnable).length ? 'Yes' : 'No'}
                </TableData>
                <TableData width='24%' compact={true} style={{display: 'block', wordBreak: 'break-word'}}>
                    {settings.conflict.map((r, i) => r.isEnable ? <p>{i + 1}. {r.label}</p> : null)}
                </TableData>
            </TableRow>
        </Table>
        <Spacer bottom={5}/>
        <Button onClick={()=>{run()}} type='primary'>
            Run Van Auto Assignment
        </Button>
        <Spacer right={3} inline/>
        <Button as={'a'} href='/company-management/vehicles/van-auto-assignment'>
            Change Settings
        </Button>
        <Spacer right={3} inline/>
        <Button type='cancel' onClick={close}>
            Cancel
        </Button>
        <Spacer bottom={5}/>
        <Spacer left={56}>
            <CheckBox
                name={`doNotVanAssignDrivers`}
                options={[{label: "Do not reassign drivers who already have a van assignment", value: true}]}
                onClick={(c) => {setDoNotVanAssignDrivers(c.target.checked);}}
            />
        </Spacer>
    </Modal>
}


const showSendAssignmentNotificationDialog = (drivers) => {
    return portalPromise((success, cancel) =>
        <SendAssignmentNotification
            close={cancel}
            drivers={drivers}
        />
    );
}

const SendAssignmentNotification = ({
    close,
    drivers
}) => {
    const [filtered, setFiltered] = useState([]);
    const [sendNotificationDriversList, setSendNotificationDriversList] = useState([]);
    const {register, handleSubmit, getValues, errors} = useForm();
    let defMessage = `Hi [Nickname]. For your scheduled shift today you are assigned to: 
[If Vehicle Assigned] Vehicle ID: [Vehicle ID] [end Vehicle Assigned] 
[If Route Code Assigned] Route Code: [Route Code Assignment] [end Route Code Assigned] 
[If Wave Time Assigned] Wave Time: [Wave Time] [end Wave Time Assigned] 
[If Staging Location Assigned] Staging location: [Staging Location] [end Staging Location Assigned] 

[If value present] Your expected time back at the station, not including breaks, is: [Amazon Expected Route Completion][end value present] See you soon!`;
    const {call, cancel} = apiFactory();

    const calcDrivers = () => {
        const { route, vehicle, stagingLocation, waveTime } = getValues();
        if (!route && !vehicle && !stagingLocation && !waveTime) {
            setFiltered([])
            return;
        }
        const d = drivers.filter(d => {
            let r = route ? d.routes.length : true;
            let v = vehicle ? d.vehicle?.unit : true;
            let s = stagingLocation ? d.stagingLocation : true;
            let w = waveTime ? d.waveTime : true;

            return r && v && s && w;
        });

        setFiltered(d);
    }

    useEffect(() => {
        calcDrivers();
    }, []);

    // Function to replace placeholders and conditional blocks
    const replaceTemplate = (template, placeholder, value, startTag, endTag) => {
        const regex = new RegExp(`\\[${startTag}\\](.*?)\\[${endTag}\\]`, "s");
        const match = template.match(regex);
        if (match && value) {
            const replacement = match[1].replace(placeholder, value);
            template = template.replace(match[0], replacement);
        } else {
            template = template.replace(regex, "");
        }
        return template;
    }

    const notifyDrivers = async (sms = false) => {
        const includeAmazonExpectedRouteCompletionTime = getValues('includeAmazonExpectedRouteCompletionTime');
        const notificationMessage = getValues("notificationMessage");
        const shiftDate = sendNotificationDriversList.length > 0 ? sendNotificationDriversList[0].shiftDate : null;
        const shiftDayIsToday = moment(shiftDate).isSame(moment(), 'day');
        let nickname = "";
        let vehicleID = "";
        let routeCodeAssignment = "";
        let stagingLocation = "";
        let amazonExpectedRouteCompletion = "";
        let waveTime = "";

        if(!shiftDayIsToday){
            const confirmation = await confirm({
                icon: true,
                text: `Are you sure you wish to send the notification with data from ${moment(shiftDate).format('MM/DD/YYYY')}?`
            });

            if (!confirmation) {
                return;
            }
        }

        toast({
            type: 'info',
            title: 'Sending notifications',
            timeout: 3000
        });

        const dataToSend = sendNotificationDriversList.map(driver => {
            
            if (driver?.name) {
                nickname = driver?.name;
            }
            
            if (driver.vehicle?.unit) {
                vehicleID = driver.vehicle?.unit; 
            }

            if (driver.routes.length) {
                routeCodeAssignment = driver.routes.join(", ");
            }

            if (driver.stagingLocation) {
                let stagingReference = (driver.stagingReference != '') ? driver.stagingReference+'/' : ''; 
                stagingLocation = stagingReference+driver.stagingLocation;
            }

            if (driver.waveTime) {
                let waveTimeReference = (driver.waveTimeReference != '') ? driver.waveTimeReference+'/' : ''; 
                waveTime = waveTimeReference+driver.waveTime;
            }

            if (includeAmazonExpectedRouteCompletionTime && driver.plannedEndTime) {
                amazonExpectedRouteCompletion = driver.plannedEndTime;
            }

            // Base message template
            let template = notificationMessage;

            // Replace nickname
            template = template.replace("[Nickname]", nickname);

            // Replace vehicle ID block
            template = replaceTemplate(template,"[Vehicle ID]",vehicleID,"If Vehicle Assigned","end Vehicle Assigned");

            // Replace route code block
            template = replaceTemplate(template,"[Route Code Assignment]",routeCodeAssignment,"If Route Code Assigned","end Route Code Assigned");

            // Replace staging location block
            template = replaceTemplate(template,"[Staging Location]",stagingLocation,"If Staging Location Assigned","end Staging Location Assigned");

            // Replace wave time block
            template = replaceTemplate(template,"[Wave Time]",waveTime,"If Wave Time Assigned","end Wave Time Assigned");

            // Replace expected route completion time block
            template = replaceTemplate(template,"[Amazon Expected Route Completion]",amazonExpectedRouteCompletion,"If value present","end value present");

            // Output the message
            const body = template.trim().replace(/\n/g, "<br>");

            return {
                driverId: driver.driverId,
                body
            };
        });

        let promises = [];
        promises.push(call({
            scenario: 'multiple',
            companyId: getCompanyId(),
            drivers: dataToSend
        }, '/api/send_twilio_notification_to_driver'));
        if (sms === true) {
            promises.push(call({
                scenario: 'multiple-diff',
                companyId: getCompanyId(),
                drivers: dataToSend,
            }, '/api/send/sms/drivers'));
        }

        Promise.all(promises).then(() => toast({
            type: 'success',
            title: `Successfully notified ${sendNotificationDriversList.length} drivers`,
            useClose: true,
            useIcon: true,
            size: 'small'
        }));

        close();
    }

    const todayShiftDriver = useMemo(() => {
        if (filtered.length > 0) {
            const todayDriver = filtered.filter(fd => {
                const shiftDate = fd.shiftDate;
                const shiftDayIsToday = moment(shiftDate).isSame(moment(), 'day');
                return shiftDayIsToday ? true : false
            })
            return todayDriver
        } else {
            return []
        }
    }, [filtered])

    return <Modal
        title='Send Assignment Notification'
        visible={true}
        setVisible={close}
        closeOnOverlay={false}
        width='640px'
    >
        <Text>Send drivers their assignment details. It can include their route code, vehicle ID and staging location. Here is what the message looks like:</Text>
        <Spacer bottom={3}/>
        <Alert.Info
            content={<>
                Hi <strong>[Nickname]</strong>. For your scheduled shift today you are assigned to: <br/>
                <em>[If Vehicle Assigned]</em> Vehicle ID: <strong>[Vehicle ID]</strong> <em>[end Vehicle Assigned]</em><br />
                <em>[If Route Code Assigned]</em> Route Code: <strong>[Route Code Assignment]</strong> <em>[end Route Code Assigned]</em><br />
                <em>[If Wave Time Assigned]</em> Wave Time: <strong>[Wave Time]</strong> <em>[end Wave Time Assigned]</em><br />
                <em>[If Staging Location Assigned]</em> Staging location: <strong>[Staging Location]</strong> <em>[end Staging Location Assigned]</em><br />
                <br/><em>[If value present] Your expected time back at the station, not including breaks, is: [Amazon Expected Route Completion] [end value present]</em><br/>               
                See you soon!
               </>}
        />
        <Spacer bottom={5} />
        <Input
            name="notificationMessage"
            label=''
            size="big"
            ref={register({
                required: "This field required.",
                validate: {
                    exists: async (message) => {
                        const newMessage = message.replace(/\n/g, ' ');
                        // Define the pattern for the placeholders
                        const pattern = /\[Nickname\].*\[If Vehicle Assigned\].*\[Vehicle ID\].*\[end Vehicle Assigned\].*\[If Route Code Assigned\].*\[Route Code Assignment\].*\[end Route Code Assigned\].*\[If Wave Time Assigned\].*\[Wave Time\].*\[end Wave Time Assigned\].*\[If Staging Location Assigned\].*\[Staging Location\].*\[end Staging Location Assigned\].*\[If value present\].*\[Amazon Expected Route Completion\].*\[end value present\]/;
                       
                        if (pattern.test(newMessage)) {
                            return true;
                        }
                        return false;
                    },
                },
            })}
            error={errors.notificationMessage && "Please write your message with correct template variable"}
            style={{ height: 138, width: 600, fontSize: "13px" }}
            defaultValue={defMessage}
            as="textarea"
        />
        <Spacer bottom={5}/>
        <Label>Only send to drivers assigned with:</Label>
        <Spacer bottom={1}/>
        <div style={{ whiteSpace: "nowrap" }}>
        <Spacer inline right={2}>
            <Toggle singleText='Route Code' on={true} name='route' ref={register} onChange={() => calcDrivers()}/>
        </Spacer>
        <Spacer inline right={3}>
            <ThirdTitle>AND</ThirdTitle>
        </Spacer>
        <Spacer inline right={2}>
            <Toggle singleText='Vehicle' name='vehicle' ref={register} onChange={() => calcDrivers()}/>
        </Spacer>
        <Spacer inline right={3}>
            <ThirdTitle>AND</ThirdTitle>
        </Spacer>
        <Spacer inline right={2}>
            <Toggle singleText='Staging' name='stagingLocation' ref={register} onChange={() => calcDrivers()}/>
        </Spacer>
        <Spacer inline right={3}>
            <ThirdTitle>AND</ThirdTitle>
        </Spacer>
        <Spacer inline right={2}>
            <Toggle singleText='Wave Time' name='waveTime' ref={register} onChange={() => calcDrivers()}/>
        </Spacer>
        </div>
        <Spacer top={3}>
            <CheckBox
                name={`includeAmazonExpectedRouteCompletionTime`}
                ref={register}
                options={[{label: "Include Amazon Expected Route Completion Time", value: true}]}
            />
        </Spacer>
        <Spacer top={3}>
            {todayShiftDriver.length > 0 &&
                <RADriverNotification
                    driversData={filtered}
                    updateDriversList={setSendNotificationDriversList}
                    driversList={sendNotificationDriversList}
                />}
        </Spacer>
        <Spacer top={5}>
            <ButtonDropdown
                options={[{
                    text: `Send Notification and SMS (${sendNotificationDriversList.length} drivers)`,
                    fn: () => handleSubmit(notifyDrivers(true))
                }]}
                disabled={!sendNotificationDriversList.length}
            >
                <Button disabled={!sendNotificationDriversList.length} type='primary' onClick={handleSubmit(notifyDrivers)}>{`Send Notification (${sendNotificationDriversList.length} drivers)`}</Button>
            </ButtonDropdown>
        </Spacer>
    </Modal>
}

export default LoadOutTools;
