import moment from 'moment';

let API = {};

const offset = new Date().getTimezoneOffset();
const offsetHours = Math.floor( offset / 60 );
const offsetMinutes = offset % 60;
const padZero = n => n < 10 ? '0' + n : n;

API.getLocalHour = (hour) => {
    hour = (hour === 0) ? 24 : hour;
    return (hour - (offsetHours));
};

API.getUtcTime = (time) => {
    time = time.split(':');
    let h = parseInt(time[0]);
    let m = parseInt(time[1]);

    m = parseInt(m) + (offsetMinutes);
    if ( m < 0 )
        m += 60;

    h = parseInt(h) + (offsetHours);
    h = h > 23
        ? h - 24
        : h < 0
            ? 24 + h
            : h;

    return `${padZero(h)}:${padZero(m)}`;
};

API.fetchFilterData = async (options, api) => {
    // send `options` to API
    const paramsFilterJson = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "getSchedularLoadOutFilterData",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                            "events"
                        ],
                        criteria: {
                            stationId: options.station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: options.date
                        },
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsFilterJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );
        return responseFilterData.data.data.result;
    } catch (error) {
        //TODO: to improve or update
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.fetchWaveData = async (options, api) => {
    // send `options` to API
    const paramsFilterJson = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "getSchedularLoadOutListingData",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                            "events"
                        ],
                        criteria: {
                            //startTime: options.startTime,
                            stationId: options.station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: options.date
                        },
                    },
                },
                Shift: {
                    findBy: {
                        criteria: {
                            station: options.station,
                            category: [1, 2, 3, 8, 5, 6],
                            isArchive: false,
                        },
                        get: "shiftTypes",
                        includes: {
                            "0":"id",
                            "1":"name",
                            "2":"startTime",
                            "3":"endTime",
                            "4":"startTimeOnDst",
                            "5":"endTimeOnDst",
                            "6":"note",
                            "7":"color",
                            "8":"textColor",
                            "9":"category",
                            "10": "isOT",
                            "invoiceType": ["id", "name"]
                        },
                        orderBy: {
                            "name": "ASC"
                        }
                    }
                }
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsFilterJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );
        return responseFilterData.data.data;
    } catch (error) {
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.removeTempData = async (api) => {
    // send `options` to API
    const paramsTempRouteJson = {
        actions: {
            response: {
                TempDriverRoute: {
                    custom: {
                        functionName: "deleteTodayLoadOutTempDriverRoute",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "status",
                            "routeId",
                            "createdBy",
                            "kickoffLog",
                            "vehicleDriverRecord",
                            "oldDriver",
                            "oldDriverRoute",
                        ],
                        criteria: {
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = api.post(
            "/api/lazy/manage/data",
            paramsTempRouteJson
        );
    } catch (error) {
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.fetchVehiclesData = async (options, api) => {
    const paramsVehicleJson = {
        actions: {
            response: {
                Vehicle: {
                    custom: {
                        functionName: "getLoadOutVehicleByStation",
                        get: "result",
                        excludes: ["location", "station", "company"],
                        criteria: {
                            stationId: options.station,
                            id:options.id,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: options.date,
                            driver: options.driver,
                        },
                    },
                },
            },
        },
    };
    try {
        const responseVehicleData = await api.post(
            "/api/lazy/manage/data",
            paramsVehicleJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );
        return responseVehicleData.data.data.result;
    } catch (error) {
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.fetchDevicesData = async (options, api) => {
    // send `options` to API
    const paramsDeviceJson = {
        actions: {
            response: {
                Device: {
                    custom: {
                        functionName: "getLoadOutDeviceByStation",
                        get: "result",
                        excludes: ["station", "company"],
                        criteria: {
                            stationId: options.station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: options.date
                        },
                    },
                },
            },
        },
    };
    try {
        const responseDeviceData = await api.post(
            "/api/lazy/manage/data",
            paramsDeviceJson
        );
        return responseDeviceData.data.data.result;
    } catch (error) {
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.fetchStationData = async (api) => {
    // send `options` to API
    const paramsStationJson = {
        actions: {
            response: {
                Station: {
                    findBy: {
                        criteria: {
                            company: localStorage.getItem('company'),
                            isArchive: false
                        },
                        get: "station",
                        includes: {
                            0: "id",
                            1: "code",
                            2: "color",
                        }
                    }
                }
            }
        }
    };
    try {
        const responseStationData = await api.post(
            "/api/lazy/manage/data",
            paramsStationJson
        );
        return responseStationData.data.data.station;
    } catch (error) {
        // alert("Error occurred. Login again, please").then(() => {
        //     window.location = "/logout";
        // });
    }
};

API.fetchPendingChanges = async (options, api) => {
    // send `options` to API
    const paramsTempDriverRouteJson = {
        actions: {
            response: {
                TempDriverRoute: {
                    custom: {
                        functionName: "getUnPublishTempDriverRouteList",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "status",
                            "routeId",
                            "createdBy",
                            "kickoffLog",
                            "vehicleDriverRecord",
                            "oldDriver",
                            "oldDriverRoute",
                            "events"
                        ],
                        criteria: {
                            wknumber: options.wknumber,
                            unpublishDataDate: options.unpublishDataDate,
                            year:options.year,
                            station: options.station,
                            loadOut:options.loadOut,
                            type:options.type
                        }
                    }
                }

            },
        },
    };

    try {
        let responseTempDriverRouteData = await api.post(
            "/api/lazy/manage/data",
            paramsTempDriverRouteJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );
        return responseTempDriverRouteData.data.data.result;
    } catch (error) {
        // if (error.response.status == "401") {
        //   window.location = "/logout";
        // }
    }
};

API.dropPendingChanges = async (drops, api) => {
    // send `options` to API
    if (drops.length > 0) {
        const dropParams = {
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "removeDropUnpublishChangesForSchedulerLoadOut",
                            get: "result",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "shiftType",
                                "schedule",
                                "status",
                                "routeId",
                                "createdBy",
                                "kickoffLog",
                                "vehicleDriverRecord",
                                "oldDriver",
                                "oldDriverRoute",
                            ],
                            criteria: {
                                tempDriverRouteId: drops,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        },
                    },
                },
            },
        };
        try {
            let dropParamsData = await api.post("/api/lazy/manage/data", dropParams);
        } catch (error) {
            // if (error.response.status == "401") {
            //   window.location = "/logout";
            // }
        }
    }
};

API.publishChanges = async (api, ids) => {
    if (ids.length > 0) {
        const params = {
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "publishChangesForSchedulerLoadOut",
                            get: "result",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "shiftType",
                                "schedule",
                                "status",
                                "routeId",
                                "createdBy",
                                "kickoffLog",
                                "vehicleDriverRecord",
                                "oldDriver",
                                "oldDriverRoute",
                            ],
                            criteria: {
                                tempDriverRouteId: ids,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        },
                    },
                },
            },
        };
        try {
            let data = await api.post(
                "/api/lazy/manage/data",
                params,
                {
                    cache: {
                        ignoreCache: true
                    }
                }
            );
        } catch (error) {
            // if (error.response.status == "401") {
            //   window.location = "/logout";
            // }
        }
    }
};

API.cortexAssistant = async (api, { station, company }) => {
    return api.post("/api/lazy/manage/data", {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "runCortexAssist",
                        get: "runCortexAssist",
                        criteria: {
                            company: company,
                            station: station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    });
};

API.fetchAvailableDrivers = async (api, options) => {
    // send `options` to API
    const paramsAvailableDriverJson = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "getOpenShiftPopupAllDrivers",
                        get: "result",
                        excludes: [
                            "stations",
                            "employmentStatus",
                            "user",
                            "assignedManagers",
                            "payRate",
                            "company",
                            "schedules",
                            "cortexLogs",
                        ],
                        criteria: {
                            stationId: options.station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: options.date
                        },
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsAvailableDriverJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );

        return responseFilterData.data.data.result;
    } catch (error) {
        // if (error.response.status == "401") {
        //   window.location = "/logout";
        // }
    }
};

API.fetchReplaceDrivers = async (api, options) => {
    // send `options` to API
    const params = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "getReplaceDriverRouteDriverListing",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                        ],
                        criteria: {
                            routeId: options.route,
                            stationId: options.station,
                            date: options.date,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            checkIncident: true
                        },
                    },
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);

        return response.data.data.result;
    } catch (error) {
        // if (error.response.status == "401") {
        //   window.location = "/logout";
        // }
    }
};

API.replaceDriver = async (api, oldRouteId, driverId, routeId) => {
    const params = {
        actions: {
            response: {
                TempDriverRoute: {
                    custom: {
                        functionName: "addReplaceDriverLoadOutTempDriverRouteData",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                        ],
                        criteria: {
                            routeId: routeId,
                            driverId: driverId,
                            oldRouteId: oldRouteId,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    };
    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.addDriver = async (api, config) => {
    const params = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "schedulerLoadOutAddDriverSave",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                        ],
                        criteria: {
                            shift: config.shift,
                            driverId: config.driver,
                            invoiceType: config.invoiceType,
                            routeStatus: config.routeStatus,
                            isVoluntary: config.isVoluntary,
                            backupStatus: config.backupStatus,
                            onCall: config.onCall,
                            startTime: config.startTime,
                            endTime: config.endTime,
                            timezoneOffset: new Date().getTimezoneOffset(),
                            date: config.date,
                            station: config.station,
                            inactiveCurrent: config.inactiveCurrent
                        },
                    },
                },
            },
        },
    };
    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.assignRoute = async (api, route, driver) => {
    // send `options` to API
    const params = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "asignOpenRouteOnDriver",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "status",
                            "routeId",
                            "createdBy",
                            "kickoffLog",
                            "vehicleDriverRecord",
                            "oldDriver",
                            "oldDriverRoute",
                        ],
                        criteria: {
                            driverId: driver,
                            routeId: route,
                            timezone: moment.tz.guess(),
                        },
                    },
                },
            },
        },
    };
    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.fetchAvailableDriversForReplace = async (api, options) => {
    // send `options` to API
    const paramsAvailableDriverJson = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "getAvailableDriversForReplace",
                        get: "result",
                        excludes: [
                            "stations",
                            "employmentStatus",
                            "user",
                            "assignedManagers",
                            "payRate",
                            "company",
                            "schedules",
                            "cortexLogs",
                        ],
                        criteria: {
                            stationId: options.station,
                            endTime: options.endTime,
                            routeId: options.routeId,
                            shiftDate: options.shiftDate,
                            driverId: options.driverId,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsAvailableDriverJson
        );

        return responseFilterData.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.getNoramlAndBackupShiftForScheduler = async (api, options) => {
    // send `options` to API
    const paramsShiftForScheduler = {
        actions: {
            response: {
                Shift: {
                    custom: {
                        functionName: "getNoramlAndBackupShiftForLoadOut",
                        criteria: {
                            company: localStorage.getItem("company"),
                            station: options.station,
                            isRescue: false,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                        get: "shift",
                        includes: {
                            0: "id",
                            1: "name",
                            2: "color",
                            3: "textColor",
                            4: "startTime",
                            5: "endTime",
                            6: "startTimeOnDst",
                            7: "endTimeOnDst",
                            8: "isOT",
                            station: {
                                "0": "id",
                                "1": "name",
                            },
                            invoiceType: ["id"],
                        },
                        excludes: ["station"],
                    },
                },
            },
        },
    };
    try {
        const responseShiftForSchedulerData = await api.post(
            "/api/lazy/manage/data",
            paramsShiftForScheduler,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );

        return responseShiftForSchedulerData.data.data.shift;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.fetchAvailableForAddDrivers = async (api, options) => {
    let param = {
        stationId: options.station,
        checkIncident: true,
        timezoneOffset: new Date().getTimezoneOffset(),
        date: moment(options.date).format('YYYY-MM-DD')
    };
    if (options.selectedStation) {
        param = {
            stationId: options.station,
            checkIncident: true,
            timezoneOffset: new Date().getTimezoneOffset(),
            date: moment(options.date).format('YYYY-MM-DD'),
            selectedStation: options.selectedStation
        };
    }
    // send `options` to API
    const paramsAvailableDriverJson = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "getOpenShiftPopupAllDriversForAddDriver",
                        get: "result",
                        excludes: [
                            "stations",
                            "employmentStatus",
                            "user",
                            "assignedManagers",
                            "payRate",
                            "company",
                            "schedules",
                            "cortexLogs",
                        ],
                        criteria: param,
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsAvailableDriverJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );

        return responseFilterData.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.checkDriverRoute = async (api, options) => {
    let param = {
        stationId: options.station,
        checkIncident: true,
        driverId: options.driver,
        timezoneOffset: new Date().getTimezoneOffset(),
        date: moment(options.date).format('YYYY-MM-DD')
    };
    if (options.selectedStation) {
        param = {
            stationId: options.station,
            driverId: options.driver,
            checkIncident: true,
            timezoneOffset: new Date().getTimezoneOffset(),
            date: moment(options.date).format('YYYY-MM-DD'),
            selectedStation: options.selectedStation
        };
    }
    // send `options` to API
    const paramsAvailableDriverJson = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "checkDriverRoute",
                        get: "result",
                        excludes: [
                            "stations",
                            "employmentStatus",
                            "user",
                            "assignedManagers",
                            "payRate",
                            "company",
                            "schedules",
                            "cortexLogs",
                        ],
                        criteria: param,
                    },
                },
            },
        },
    };
    try {
        const responseFilterData = await api.post(
            "/api/lazy/manage/data",
            paramsAvailableDriverJson,
            {
                cache: {
                    ignoreCache: true
                }
            }
        );

        return responseFilterData.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.fetchInvoiceTypeForAddDrivers = async (api, options) => {
    // send `options` to API
    const paramsInvoiceType = {
        actions: {
            response: {
                InvoiceType: {
                    custom: {
                        functionName: "getAllInvoiceTypeByStation",
                        get: "result",
                        excludes: [
                            "rateRule",
                            "shiftTemplates",
                            "invoiceType",
                            "driverRoutes",
                            "company",
                            "station",
                            "shiftType",
                            "tempDriverRoutes",
                            "scheduleDesigns",
                            "skill",
                            "balanceGroup",
                        ],
                        includes: ["id", "name", "billableHour", "invoiceType"],
                        criteria: {
                            stationId: options.station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsInvoiceType,
            {
                cache: {
                    ignoreCache: true
                }
            });

        return response.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.removeRequest = async (api, routeId) => {
    const paramsRemoveRequest = {
        actions: {
            response: {
                TempDriverRoute: {
                    custom: {
                        functionName: "removeDropRequest",
                        get: "result",
                        excludes: [],
                        criteria: {
                            id:routeId,
                            isLoadOut:true,
                            timezoneOffset: new Date().getTimezoneOffset(),
                         }
                    }
                }
            }
        }
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsRemoveRequest);
        return response.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};
API.checkRouteCodeForDriverAlias = async (api, criteria) => {
    const paramsRemoveRequest = {
        actions: {
            response: {
                RouteCodeAssignment: {
                    custom: {
                        functionName: "checkRouteCodeForDriverAlias",
                        get: "result",
                        excludes: [],
                        criteria: criteria
                    }
                }
            }
        }
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsRemoveRequest);
        return response.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
    return true;
};
API.updateTidAndDriverAlias = async (api, criteria) => {
    const paramsRemoveRequest = {
        actions: {
            response: {
                RouteCodeAssignment: {
                    custom: {
                        functionName: "updateTidAndDriverAlias",
                        get: "result",
                        excludes: [],
                        criteria: criteria
                    }
                }
            }
        }
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsRemoveRequest);
        return response.data.data.result;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
    return true;
};

API.deleteDriverRoute = async (api, driverId, effectiveDate) => {
    const paramsRemoveRequest = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "deleteDriverRoute",
                        get: "result",
                        excludes: [],
                        criteria: {
                            driverId:driverId,
                            effectiveDate:moment(effectiveDate).format('YYYY-MM-DD'),
                         }
                    }
                }
            }
        }
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsRemoveRequest);
        return true;
    } catch (error) {
        return false;
    }
};


API.passwordResetMail = async (api, company, email, driverName, password) => {
    const passwordResetParam = {
        "company":company,
        "email":email,
        "name":driverName,
        "password": password,
        "isLoadout" : true,
    };
    try {
        const response = await api.post("/api/send-employee-registration-mail", passwordResetParam);
        return true;
    } catch (error) {
        return false;
    }
};

API.passwordReset = async (api, userId, password, terminateAllSession) => {
    const changePassword = {
        "key": "id",
        "value":userId,
        "password":password,
        "terminateAllSession": terminateAllSession,
        "ignoreLast6Password": true
    };
    try {
        const response = await api.post("/api/user/update_password/web", changePassword);
        return response;
    } catch (error) {
        return false;
    }
};
API.getAllDrivers = async (api,station,selectedDate) => {
    const paramsInvoiceType = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        functionName: "getDrivers",
                        get: "result",
                        excludes: [
                            "vehicleDriverRecords",
                            "driverIncidentRecords",
                            "driverRoutes",
                            "user",
                            "company",
                            "drivers",
                            "stations",
                            "employmentStatus",
                            "skills",
                            "scores",
                            "employmentStatus",
                            "assignedManagers",
                            "payRate",
                            "schedules",
                            "emergencyContacts",
                            "driverSkills",
                            "incidents"
                        ],
                        includes: {
                            "0": "id",
                            "1": "email",
                            "4": "fullName",
                            "user": {"0":"id","1":"email"},
                        },
                        criteria: {
                            station: station,
                            timezoneOffset: new Date().getTimezoneOffset(),
                        },
                    },
                },
            },
        },
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramsInvoiceType,
            {
                cache: {
                    ignoreCache: true
                }
            });

        return response.data.data.result.driver;
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
    }
};

API.addImportPicklistData = async (api,picklistData,stationId,companyId,date,loginUser) => {
    const paramPicklist = {
            actions: {
                response: {
                    Picklist: {
                        custom: {
                            functionName: "addPicklistData",
                            get: "result",
                            excludes: [],
                            criteria: {
                                picklistData: picklistData,
                                stationId:stationId,
                                companyId:companyId,
                                date:date,
                                loginUser:loginUser,
                            },
                        },
                    },
                },
            },
    };
    try {
        const response = await api.post("/api/lazy/manage/data", paramPicklist);
        return true;
    } catch (error) {
        return false;
    }
};

API.dropOpenRoutes = async (api, routeIds) => {
    const paramsDropOpenRouteJson = {
        actions: {
            response: {
                DriverRoute: {
                    custom: {
                        functionName: "dropOpenRouteByDriverRoute",
                        get: "result",
                        excludes: [
                            "skill",
                            "vehicle",
                            "device",
                            "driver",
                            "station",
                            "shiftType",
                            "schedule",
                            "vehicleDriverRecord",
                            "status",
                            "tempDriverRoutes",
                            "kickoffLog",
                            "returnToStation",
                            "oldDriver",
                            "oldDriverRoute",
                            "driverRoutes",
                            "oldTempDriverRoutes",
                        ],
                        criteria: {
                            routeId: routeIds
                        },
                    },
                },
            },
        },
    };
    try {
        const responseVehicleData = await api.post("/api/lazy/manage/data", paramsDropOpenRouteJson);
        return true
    } catch (error) {
        if (error.response.status == "401") {
            window.location = "/logout";
        }
        return false;
    }
};

export default API;
