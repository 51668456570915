import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import {Theme, ThirdTitle, Spacer, Text, Input, Password, Button, Alert} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import {patternEmail, validatePassword, validateObjectNull, validatePasswordBooleanObject} from "../../patterns";
import {Link, useHistory} from "react-router-dom";
import {engine, showErrorToast} from "../../Utilities/index";
import {setToken, login, setChatIdentifier, setStreamChatIdentifier, setIsEnabled, useAuth, setIsEnableSMS} from "../../Auth";
import Footer from "../../components/Footer";
import moment from "moment-timezone";
import {LoadingView} from "../../components/Loading";
import {useSelectedStation} from "../../components/StationSelector";
import {setMainStation, setMainStationId, resetApp } from "../../Utilities";
import {MainImage} from "../../components/Image/logo";
import {setItemToStorage} from "../../AppTwilioConversation/utils";
import { alert } from "../../components/Alert";
import { PasswordErrorValidate } from "../../components/Password/PasswordErrorValidate";

const Body = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const Section = styled.section`
    background: ${Theme.colors.info.bg};
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

`;

const Card = styled.div`
    background: ${Theme.colors.neutrals.white};
    box-shadow: 0px 3px 6px #33333326;
    padding: 20px;
    border-radius: ${Theme.defaultRadius};
    max-width: 340px;
`;

const Btn = styled(Button)`
    width: 100%;
`;

const BtnCentered = styled(Btn)`
    text-align: center;
`;

const errorMessages = {
    email: {
        required: "You need to provide an email",
        pattern: "Enter a valid email"
    },
    password: {
        required: "You need to provide a password to log in",
        pattern: "Your password must respect all rules"
    }
};

async function checkUserAuth(data, setUserError, changeRoute, setLoading, setSelected, setAuthData) {
    try {
        const response = await login.post("/api/login_check", {
            username: data.email,
            password: data.password,
            isCorporate: false,
            timezone: moment.tz.guess()
        });

        if (response.status === 200) {
            if (response?.data?.isLock && response.data.isLock == true) {
                setUserError(response.data.message);
                setLoading(false);
            }  else if (response.data.position === "Delivery Associate") {
                setUserError("Driver is not accessible for login.");
                setLoading(false);
            } else if (response.data.employeeStatus != 2) {
                setUserError("You are not authorised to login.");
                setLoading(false);
            } else {
                let identity = response.data["chatIdentifier"];
                let streamChatIdentifier = response.data["streamChatIdentifier"];
                let token = response.data.token;
                setChatIdentifier(identity);
                setStreamChatIdentifier(streamChatIdentifier);
                setToken(token);
                localStorage.setItem("userId", response.data.userId);
                localStorage.setItem("company", response.data.company);
                localStorage.setItem("chatTeam", 'company-'+response.data.company);
                localStorage.setItem("driverId", response.data.driverId);
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("friendlyName", response.data.friendlyName);
                localStorage.setItem("companyName", response.data.companyName);
                localStorage.setItem("position", response.data.position);
                localStorage.setItem("role", response.data.role);
                localStorage.setItem("title", response.data.title);
                localStorage.setItem("selectedStation", response.data.selectedStation);
                localStorage.setItem("currentUserRole", response.data.currentUserRole);
                localStorage.setItem("isResetPassword", response.data.isResetPassword);
                localStorage.setItem("recurringWeeks", response.data.recurringWeeks);
                localStorage.setItem("showMobileReleaseShift", JSON.stringify(response.data.showMobileReleaseShift));
                localStorage.setItem("showMobileSwapShift", JSON.stringify(response.data.showMobileSwapShift));
                localStorage.setItem("isADP", response.data.isADP);
                setMainStationId(response.data.stationId);
                setMainStation(response.data.station);
                setSelected(response.data.selectedStation);
                setIsEnabled(response.data.isEnabled);
                setIsEnableSMS(response.data.isEnableSMS);
                localStorage.setItem("enableAutomatedScorecardCoachingMetric", response.data.automatedScorecardCoachingMetric);   
                localStorage.setItem("allowsDispatcherPermission", response.data.allowsDispatcherPermission); 
                let stationsResponse = await engine().post("/api/lazy/manage/data", {
                    "actions": {
                        "response": {
                            "Station": {
                                "custom": {
                                    functionName: "getStationByRole",
                                    get: 'Stations',
                                    criteria: {
                                        company: response.data.company
                                    },
                                    includes: {
                                        "0": "id",
                                        "1": "name",
                                        "2": "code",
                                    },
                                }
                            },
                            "Role": {
                                "custom": {
                                    functionName: "getCompanyRolesArray",
                                    get: 'Roles',
                                    criteria: {
                                        companyId: response.data.company
                                    },
                                }
                            },
                        }
                    }
                });
                try {
                    if (stationsResponse.data.data.Roles) {
                        if (Array.isArray(stationsResponse.data.data.Roles)) {
                            setItemToStorage('roles', stationsResponse.data.data.Roles);
                        } else {
                            setItemToStorage('roles', []);
                        }
                    }
                } catch (e) {
                    await showErrorToast(e, 'Error setting roles.');
                }
                window.localStorage.setItem('stations', JSON.stringify(stationsResponse.data.data.Stations));
                let homeVar = localStorage.getItem("homepage");
                if (!homeVar) {
                    homeVar = (localStorage.getItem("currentUserRole") == "ROLE_CHAT_USER")?'/chat':'/dashboard';
                    localStorage.setItem("homepage", homeVar);
                }
                changeRoute.push(homeVar);
                setAuthData({authToken: token, twilioIdentity: identity});
            }
        }
    } catch (error) {
        setLoading(false);
        if (error.response) {
            setUserError(error.response.data.message);
        } else {
            if (error.message) {
                setUserError(error.message);
            } else {
                setUserError(JSON.stringify(error));
            }
        }
    }
}

const App = changeRoute => {
    let history = useHistory();
    const {register, handleSubmit, errors, getValues} = useForm();
    const {email, password} = getValues();
    const [userError, setUserError] = useState();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useSelectedStation(0);
    const {setAuthData} = useAuth();
    const [validation, setValidation] = useState(validateObjectNull);

    const onSubmit = async data => {
        setLoading(true);
        await checkUserAuth(data, setUserError, history, setLoading, setSelected, setAuthData);
    };

    // auto login from website
    const autoLogin = evt => {
        if (!evt.data || !evt.data.token)
            return;

        onSubmit(evt.data);
    };

    useEffect(() => {
        window.addEventListener("message", autoLogin, false);
    }, []);

    const handleChange = (e) => {
        const password = e.target.value;
        if (e.target.value.length == 0) {
            setValidation(validateObjectNull);
        } else {
            setValidation(validatePasswordBooleanObject(password));
        }
    };

    return (
        <Body>
            <Section>
                <div>
                    <MainImage/>
                    <Spacer top={6}/>
                    <Card>
                        <ThirdTitle>Sign in</ThirdTitle>
                        <Spacer top={2}/>

                        <Text>You can sign in using your dspworkplace account to access our services.</Text>
                        {userError && <Alert.Error title="Error" content={userError}/>}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                name="email"
                                label="Email"
                                ref={register({
                                    required: true,
                                    pattern: patternEmail
                                })}
                                error={errors.email && errorMessages.email[errors.email.type]}
                                valid={!errors.email && email && email.length > 0}
                                autoComplete="off"
                            />

                            <Spacer top={4}/>

                            <Password
                                name="password"
                                label="Password"
                                help={<PasswordErrorValidate validation={validation}/>}
                                ref={register({
                                    required: true,
                                    validate: async (value) => {
                                        if (value) {
                                            return Object.values(validation).every((value) => value === null || value === false);
                                        }
                                    }
                                })}
                                onChange={handleChange}
                                error={errors.password && errors.password.message}
                                valid={!errors.password && password && password.length > 0}
                            />
                            <Spacer top={4}/>

                            {!loading && <Btn type="primary">Login</Btn>}
                            {loading && <LoadingView containerHeight={"56px"} imgWidth={"48px"}/>}

                            <Spacer top={1}/>
                        </form>
                        <Link to="/forgot-password">
                            <Btn type="cancel">Forgot my password</Btn>
                        </Link>
                    </Card>
                    <Spacer top={6}/>

                   {/* <Link to="https://dspworkplace.com/register">
                        <BtnCentered type="cancel" size="small">
                            Register for a new account
                        </BtnCentered>
                    </Link>*/}
                </div>
            </Section>
            <Footer/>
        </Body>
    );
};

export default App;
