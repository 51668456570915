import { Icon, Text } from '@dspworkplace/ui';
import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import { Grid, SearchBar, SearchContext, SearchContextManager, SuggestionBar } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { SubTitle, Spacer, Dropdown, Button} from "@dspworkplace/ui";
import { engine } from "../../Utilities";
import { useChatTrackedState } from '../store';
// use @giphy/js-fetch-api to fetch gifs
// apply for a new Web SDK key. Use a separate key for every platform (Android, iOS, Web)
const gf = new GiphyFetch('ljED2XvGjwzzPLlfvqwaHO9uFpQqdt5F')

function GiphyModal({ activeGiphyType, setActiveGiphyType, channel, setStickerModalVisible, isPage }) {
    const [searchValue, setSearchValue] = useState('trending')
    const { searchKey, } = useContext(SearchContext);
    const [videoOptions, setVideoOptions] = useState([]);
    const [videoMetricKey, setVideoMetricKey] = useState(null);
    const state = useChatTrackedState();
    const { trainingVideo } = state;
    console.log(trainingVideo.length);
    const fetchGifs = (offset) => gf.search(searchValue, { offset, limit: 10, type: activeGiphyType })

    const fetchStickers = (offset) => gf.trending({ offset, limit: 10, type: 'stickers' })

    async function sendGiphyMessage(data) {
        console.log(data)
        await channel?.sendMessage({
            text: '',
            attachments: [{
                type: data?.type === 'gif' ? 'giphy' : 'sticker',
                image_url: data?.images?.original?.url,
                thumb_url: data?.images?.original?.url,
            }]
        })
        await setStickerModalVisible(false)
    }

    // useEffect(() => {
    //     let metricVideoOptions = [];
    //     metricVideoOptions = trainingVideo.map((m) => ({
    //         name: m.metric,
    //         value: m.metricKey,
    //     }));
    //     setVideoOptions(metricVideoOptions);
    // }, [trainingVideo]);

    const sendMetricVideo = async () => {
        const message = await channel?.sendMessage({
            text: videoMetricKey,
            messageType: "metricVideo",
        });
    }
    
    return (
        <SearchContextManager apiKey="ljED2XvGjwzzPLlfvqwaHO9uFpQqdt5F" options={{ type: 'gifs' }} key={activeGiphyType} theme={{ searchbarHeight: 55 }}>
            <div style={{ display: 'block', height: '400px', width: 'auto', padding: '0px 0px 32px 42px' }} >
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                    <TabGiphy backgroundColor={activeGiphyType === 'gifs' ? "#0071bc" : '#ffffff'} onClick={() => {
                        setActiveGiphyType("gifs")
                        setSearchValue('trending')
                    }} >
                        <Text style={{ color: activeGiphyType === 'gifs' ? '#FFFFFF' : '#7a7a7a' }} >GIFs</Text>
                    </TabGiphy>
                    <TabGiphy backgroundColor={activeGiphyType === 'stickers' ? "#0071bc" : '#ffffff'} onClick={() => {
                        setActiveGiphyType("stickers")
                        setSearchValue('trending')
                    }}>
                        <Text style={{ color: activeGiphyType === 'stickers' ? '#FFFFFF' : '#7a7a7a' }}>Stickers</Text>
                    </TabGiphy>
                    {/* <TabGiphy backgroundColor={activeGiphyType === 'trainingVideo' ? "#0071bc" : '#ffffff'} onClick={() => {
                        setActiveGiphyType("trainingVideo");
                    }}>
                        <Text style={{ color: activeGiphyType === 'trainingVideo' ? '#FFFFFF' : '#7a7a7a' }}>Training Videos</Text>
                    </TabGiphy> */}
                    <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setStickerModalVisible(false)} >
                        <Icon.TimesNarrow size={'20px'} color='#acacac' />
                    </div>
                </div>
                {activeGiphyType != "trainingVideo" && <><SearchBar
                    onEnter={(value) => setSearchValue(value)}
                    key={searchKey}
                    placeholder='Search...'
                />
                <SuggestionBar /></>}
                <Spacer top={1}/>
                <div style={{ display: 'flex', height: '100%', overflow: 'auto', margin: '7px 0' }}>
                    {/* <Grid key={searchKey} columns={3} width={800} fetchGifs={fetchGifs} /> */}
                    {activeGiphyType != "trainingVideo" ? (
                        <Grid
                            width={isPage ? 1200 : 550}
                            columns={isPage ? 8 : 4}
                            fetchGifs={fetchGifs}
                            gutter={7}
                            key={searchValue}
                            onGifClick={(gif) => sendGiphyMessage(gif)}
                            noLink={true}
                            hideAttribution={true}
                            noResultsMessage={"No data found"}
                        />
                    ) : (
                        <div style={{ position: "absolute" }}>
                            <SubTitle>Driver Training Video</SubTitle>
                            <Spacer top={1} />
                            <Dropdown
                                name="videoId"
                                placeholder="Select Video"
                                options={videoOptions}
                                size={"large"}
                                visibleOptionsQty={5}
                                onChange={(v) => {
                                    setVideoMetricKey(v.value);
                                }}
                            />
                            <Spacer inline right={3}/>
                            <Button size="medium" type="primary" onClick={() => sendMetricVideo()}>
                                Add
                            </Button>
                        </div>
                    )}

                </div>
                {/* {activeGiphyType === 'gifs' ?
                <div style={{ display: 'flex', height: '100%', overflow: 'auto', margin: '7px 0' }}>
                    <Grid width={isPage ? 1200 : 550} columns={isPage ? 8 : 4} fetchGifs={fetchGifs} gutter={7} key={activeGiphyType} onGifClick={(gif) => sendGiphyMessage(gif)} noLink={true} hideAttribution={true} />
                </div>
                :
                <div style={{ display: 'flex', height: '100%', overflow: 'auto', margin: '7px 0' }}>
                    <Grid width={isPage ? 1200 : 550} columns={isPage ? 8 : 4} fetchGifs={fetchStickers} gutter={7} gifHeight="120px" key={activeGiphyType} onGifClick={(gif) => sendGiphyMessage(gif)} noLink={true} hideAttribution={true} />
                </div>
            } */}
            </div>
        </SearchContextManager>
    )
}

export default GiphyModal


const TabGiphy = styled.div`
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : '#ffffff')};
    height: 30px;
    width: 125px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0 5px 0 0;
    cursor: pointer;
`