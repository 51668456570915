import { dialogPromise, portalPromise } from "../../../../components/Dialog";
import React, { useState } from "react";
import { Input, TagInput, Spacer, Button, Alert, Text, ThirdTitle } from '@dspworkplace/ui';
import Modal from "../../../../components/Modal";
import { useForm } from "react-hook-form";
import API from "./api";
import { confirm } from "../../../../components/Confirm";
import Loading from "../../../../components/Loading";
import { Table, TableData, TableHeader, TableRow } from "../../../../components/Table";
import { Tag } from "../../../../components/UI";
import { Question } from "../../../../components/UI";

const WaveMapForm = ({ success, cancel, waveMapObj, idWaveMap, station }) => {
    const defaultLocations = waveMapObj && waveMapObj.waveTime ? waveMapObj.waveTime.map((l) => ({ value: l, name: l })) : [];
    let defaultValues = {};
    if (waveMapObj) {
        defaultValues = { name: waveMapObj.name, waveTime: waveMapObj.waveTime };
    }

    const [state, setState] = useState({ status: idWaveMap ? 'PENDING' : 'READY', error: '', waveTime: defaultLocations });
    const { register, handleSubmit, reset, errors } = useForm({ defaultValues: defaultValues });

    React.useEffect(() => {
        if (idWaveMap) {
            API.getWaveTimesMap(idWaveMap).then(
                resolve => {
                    const waveMap = resolve.data.data.WaveMap;
                    const waveTime = waveMap.waveTime.map((l) => ({ value: l, name: l }));
                    reset({ name: waveMap.name, waveTime: waveMap.waveTime });
                    setState({ ...state, status: 'READY', waveTime: waveTime })
                }
            )
        }
    }, [idWaveMap, waveMapObj])

    const onSubmit = (data) => {
        const stationMapIdentifier = idWaveMap ? idWaveMap : (waveMapObj ? waveMapObj.id : false);

        setState({ ...state, status: 'SUBMITTING' })
        data.station = station.id;
        // if (data.waveTime) data.waveTime = JSON.stringify(data.waveTime);

        API.addMapLocation(data, stationMapIdentifier).then(
            resolve => {
                if (resolve) success(resolve);
            },
            error => {
                setState({ ...state, status: 'CREATE_ERROR', error: error })
            }
        )
    }

    return (
        <Modal
            width={'340px'}
            visible={true}
            title={'New Wave Times'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            {state.status === 'PENDING'
                ?
                <Loading style={{ height: 80, margin: '50px auto', display: 'block' }} />
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                    {(waveMapObj && (waveMapObj.name == null || waveMapObj.name == '')) ?
                        <Input
                            name={'name'}
                            label={'Wave Name'}
                            ref={register()}
                            errors={errors.location && errors.location.name}
                        />
                        :
                        <Input
                            name={'name'}
                            label={'Wave Name'}
                            ref={register({ required: 'Field Required' })}
                            errors={errors.location && errors.location.name}
                        />
                    }
                    <Spacer top={4} />
                    <TagInput
                        name={'waveTime'}
                        ref={register}
                        label={'Wave Time'}
                        options={state.waveTime}
                        canCreate={true}
                    />
                    {state.status === 'CREATE_ERROR' &&
                        <Spacer top={5}>
                            <Alert.Error
                                title={`Unable to save the wave times`}
                                content={'Try to refresh the page, if the problem persist please contact support.'} />
                        </Spacer>
                    }
                    <Spacer top={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type={'primary'}
                            disabled={state.status == 'SUBMITTING' ? true : false}
                        >
                            {state.status == 'SUBMITTING' ? 'Saving Wave Time' : 'Save Wave Time'}
                        </Button>
                    </Spacer>
                </form>
            }
        </Modal>
    )
}

const openWaveMapForm = ({ station, idWaveMap, waveMapObj }) => {
    return dialogPromise((success, cancel) => {
        return <WaveMapForm
            success={success}
            cancel={cancel}
            idWaveMap={idWaveMap}
            waveMapObj={waveMapObj}
            station={station}
        />
    })
}

const ImportSpreedHelper = ({ success, cancel }) => (
    <Modal
        width={'540px'}
        visible={true}
        title={'Using the Wave Time Import Tool'}
        closeOnOverlay={true}
        setVisible={cancel}
    >
        <Spacer top={2} />
        <Text>To quickly import your station map, first create your station in a spreadsheet where the columns are the Reference Locations (Lane, Door, Pad, etc.) and under each reference location, list its assigned Staging waveTime.</Text>
        <Spacer top={3} />
        <ThirdTitle>Example: </ThirdTitle>
        <img style={{ width: '100%' }} src={'https://images.dspworkplace.com/stationMapSpreed.png'} />
        <Spacer top={3} />
        <Text>If your station does not use reference waveTime, simply list all staging waveTime under a single column labeled by the station's 4 character code.</Text>
        <Text>Next, select the entire station you just created, and then copy the data:</Text>
        <Spacer top={3} />
        <img style={{ width: '100%' }} src={'https://images.dspworkplace.com/stationMapSpreedCopy.png'} />
        <Spacer top={2} />
        <Text>Once you have copied the data, proceed to the Wave Time located in: Company Management &gt; Stations &gt; Manage, then select the Import XLS button.</Text>
        <Spacer top={3} />
        <img style={{ width: '100%' }} src={'https://images.dspworkplace.com/stationMap.png'} />
        <Spacer top={2} />
        <Text>Next, simply paste the station data you copied into the Wave Time Import box below:</Text>
        <Spacer top={3} />
        <img style={{ width: '100%' }} src={'https://images.dspworkplace.com/stationMapImport.png'} />
        <Spacer top={2} />
        <Text>Once complete, it should process the data and create the Wave Time automatically:</Text>
        <Spacer top={3} />
        <img style={{ width: '100%' }} src={'https://images.dspworkplace.com/stationMapImported.png'} />
        <Spacer top={2} />
        <Text>If it looks good, be sure to scroll down to the bottom and press the Save button.</Text>
        <Text>If there are issues, simply make the changes in your spreedsheet then copy and paste again</Text>
        <Spacer top={2} />
    </Modal>
)

const openImportSpreedHelper = () => {
    return portalPromise((success, cancel) => {
        return <ImportSpreedHelper
            success={success}
            cancel={cancel}
        />
    })
}

const StationMapSpreadCopyForm = ({ success, cancel, station }) => {
    const [temp, setTemp] = useState({ data: [], status: 'PENDING' });

    const handlePaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text');
        let textRows = text.split('\n');
        let header = textRows.slice(0, 1)[0]?.split('\t');
        if (header.length > 0) {
            header = header.filter((h) => h.length > 0)
        }

        const table = header.map((h, k) => {
            return {
                name: h,
                waveTime: textRows.reduce((acc, t, i) => {
                    if (i > 0 && t.trim().length > 0) {
                        const line = t.split('\t');
                        const data = line[k].trim();
                        if (data && data.length > 0) {
                            acc.push(data);
                        }
                    }
                    return acc;
                }, [])
            }
        })
        setTemp({ ...temp, data: table, status: 'PROCESSED' })
    }

    return (
        <Modal
            width={'640px'}
            visible={true}
            title={'Wave Time Import'}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <div>
                <Input
                    onPaste={handlePaste}
                    style={{ width: '590px' }}
                    placeholder={'Copy all Staging and Reference waveTime and paste here.'}
                />
                <Spacer top={3} bottom={2}>
                    <span style={{ cursor: 'pointer', display: 'block' }} onClick={() => openImportSpreedHelper()}>
                        <ThirdTitle style={{ display: 'inline' }}>Need Help </ThirdTitle>
                        <Question>?</Question>
                    </span>
                </Spacer>

                {temp.status === 'CREATING' &&
                    <Loading style={{ height: 80, margin: '50px auto', display: 'block' }} />
                }

                {temp.status === 'PROCESSED' &&
                    <Table width={'100%'}>
                        <TableHeader
                            headers={[
                                { label: 'Wave Name', width: '25%' },
                                { label: 'Wave Time', width: '75%' },
                            ]}
                        />
                        {temp.data.map((t, k) => {
                            return (
                                <TableRow key={k} style={{ flexWrap: 'wrap', position: 'relative' }} >
                                    <TableData width='25%'>
                                        {t.name}
                                    </TableData>
                                    <TableData width='75%' style={{ display: 'grid', gridTemplateColumns: `repeat(4,minmax(40px, auto) ) `, gridGap: 8 }}>
                                        {t.waveTime.map((f, k) => <Tag key={k} style={{ marginRight: 4 }} >{f}</Tag>)}
                                    </TableData>
                                </TableRow>
                            )
                        })}
                    </Table>
                }

                {temp.status === 'PROCESSED' &&
                    <Spacer top={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type={'primary'}
                            onClick={() => {
                                setTemp({ ...temp, status: 'CREATING' })

                                API.addMultipleMapLocation(temp.data.map((d) => { d.waveTime = JSON.stringify(d.waveTime); return d }), station).then(
                                    resolve => {
                                        success(true);
                                    },
                                    error => {
                                        setTemp({ ...temp, status: 'PENDING' })
                                    }
                                )
                            }}
                        >
                            Save Data
                        </Button>
                    </Spacer>
                }
            </div>
        </Modal>
    )
}

const openStationMapSpreadCopyForm = ({ station }) => {
    return dialogPromise((success, cancel) => {
        return <StationMapSpreadCopyForm
            success={success}
            cancel={cancel}
            station={station}
        />
    })
}

const openWaveTimesArchiveForm = async (name) => {
    return confirm({
        icon: true,
        text: `Do you really want to archive "${name}"?`
    });
}

export { openWaveMapForm, WaveMapForm, openWaveTimesArchiveForm, openStationMapSpreadCopyForm }