import React, {Fragment, useEffect, useState} from 'react';
import {matchPath, NavLink, Route, Switch, useLocation, useParams} from 'react-router-dom';
import {Icon, Spacer, SubTitle, Theme} from '@dspworkplace/ui';
import InvoiceType from "./InvoiceTypes";
import Rates from "./Rates";
import ShiftTypes from "./ShiftTypes";
import Schedules from "./Schedules";
import {useAuth, getCompanyId} from "../../../Auth";
import SkillRatesPage from "./SkillRates";
import BalanceGroup from "./BalanceGroup";
import VanSupplies from './VanSupplies';
import StatingLocation from './StagingLocation';
import WaveMap from './WaveMap';
import {Link, Tag} from "../../../components/UI";
import {Back, Body, Content, Menu, Navigator} from "../../../components/Menus/Navigator";
import LoadOutTemplate from "../../WorkFlowBuilders/LoadOutRTSTemplate/Temp/LoadOutTemplate";
import RTSTemplate from "../../WorkFlowBuilders/LoadOutRTSTemplate/Temp/RTSTemplate";
import {AccessCheck, IsGranted} from "../../../security";
import {showDefaultErrorToast} from "../../../Utilities";

const SkillRate = (state) => {
    return (
        <SkillRatesPage state={state.state} />
    );
};

const InvoiceTypes = (state) => {
    return (
        <InvoiceType state={state.state} />
    );
};

const ShiftType = (state) => {
    return (
        <ShiftTypes state={state.state} />
    );
};

const Schedule = (state) => {
    const {sid} = useParams();
    return (
        <Schedules state={state.state} sid={sid} />
    );
};

const App = () => {
    const {pathname,state} = useLocation();
    const {api, logout} = useAuth();
    const [station,setStation] = useState();
    const [tabOptions,setTabOptions] = useState([
        { path: '/schedules/:sid', name: 'Schedules', icon: Icon.Calendar, component: Schedule, isRender: false },
        { path: '/schedules', name: 'Schedules', icon: Icon.Calendar, component: Schedule, isRender: true },
        { path: '/shift_types', name: 'Shift Types', icon: Icon.Clock, component: ShiftType, isRender: true },
        { path: '/balance_group', name: 'Balance Group', icon: Icon.Balance, component: BalanceGroup, isRender: true },
        { path: '/invoice_types', name: 'Invoice Types', icon: Icon.Invoice, component: InvoiceTypes, feature: AccessCheck.features.ST_INVOICE_TYPES, isRender: true },
        { path: '/rates', name: 'Rate Card', icon: Icon.Dollar, component: Rates, feature: AccessCheck.features.ST_RATE_CARD, isRender: true },
        { path: '/skill_rates', name: 'Skill Rates', icon: Icon.Skill, component: SkillRate, feature: AccessCheck.features.ST_SKILL_RATES, isRender: true },
        { path: '/load_out', name: 'Load Out', icon: Icon.PackageCheck, component: LoadOutTemplate, isRender: true },
        { path: '/rts', name: 'RTS', icon: Icon.PackageBack, component: RTSTemplate, isRender: true },
        { path: '/staging_location', name: 'Station Map', icon: Icon.Card, component: StatingLocation, isRender: true },
        { path: '/wave_time', name: 'Wave Times', icon: Icon.Wave, component: WaveMap, isRender: true },
    ]);
    const {id} = useParams();
    

    if(!localStorage.getItem('token')){
        window.location = '/login';
    }

    useEffect(()=>{
        // if(state && state.station){
        //     setStation(state.station);
        //     getUserStation(state.station.id);
        // }else{
            getStation().then();
        // }

        /*
        async function getUserStation(id){
            let FullRole = ['ROLE_OWNER', 'ROLE_OPERATIONS_ACCOUNT_MANAGER', 'ROLE_OPERATIONS_MANAGER', 'ROLE_FLEET_MANAGER', 'ROLE_HR_ADMINISTRATOR', 'ROLE_SAFETY_MANAGER', 'ROLE_CENTRAL_DISPATCHER'];
            let ParsiallyRole = ['ROLE_DISPATCH', 'ROLE_STATION_MANAGER', 'ROLE_ASSISTANT_STATION_MANAGER'];
            if(FullRole.indexOf(localStorage.getItem('currentUserRole')) > -1){
                let tempTabOptions = tabOptions;
                tempTabOptions.push({path:'/rates',name:'Rate Card',icon:Icon.Dollar, component:Rates, feature: AccessCheck.features.ST_RATE_CARD});
                setTabOptions(tempTabOptions);
            }
            else if (ParsiallyRole.indexOf(localStorage.getItem('currentUserRole')) > -1 && localStorage.getItem('driverId') !== null){
                const params={
                    "actions": {
                        "response": {
                            "Driver": {
                                "find": {
                                    "get": "Driver",
                                    "id": localStorage.getItem('driverId'),
                                    "excludes": [ "Company","vehicleDriverRecords", "driverIncidentRecords", "user", "drivers", "stations", "employmentStatus", "skills", "scores", "employmentStatus", "assignedManagers", "payRate", "schedules", "emergencyContacts" ],
                                    "includes": {
                                        "stations": ["id", "name"],
                                    }
                                }
                            },
                        }
                    }
                };
                let response = await api.post('/api/lazy/manage/data', params);

                if(response.data.data.Driver && response.data.data.Driver.stations){
                    let station_check = true;

                    await response.data.data.Driver.stations.forEach((item, i) => {
                        if(item.id == id){
                            // let tempTabOptions = tabOptions;
                            // tempTabOptions.push({path:'/rates',name:'Rate Card',icon:Icon.Dollar, component:Rates});
                            // setTabOptions(tempTabOptions);
                            setTabOptions([
                                {path:'/shift_types',name:'Shift Types',icon:Icon.Clock, component:ShiftType},
                                {path:'/balance_group',name:'Balance Group',icon:Icon.Balance, component:BalanceGroup},
                                {path:'/schedules',name:'Schedules',icon:Icon.Calendar, component:Schedule},
                                {path:'/skill_rates',name:'Skill Rates',icon:Icon.Skill, component:SkillRate},
                                {path:'/invoice_types',name:'Invoice Types',icon:Icon.Invoice, component:InvoiceTypes},
                                {path:'/rates',name:'Rate Card',icon:Icon.Dollar, component:Rates, feature: AccessCheck.features.ST_RATE_CARD}
                            ]);
                        }
                    });
                }
            }
        }
        */

        async function getStation(){
            const params={
                "actions": {
                    "response": {
                        "Station": {
                            "findOneBy": {
                                "criteria": {
                                    "id": id,
                                    "company": getCompanyId()
                                    //'isEnabled':true
                                },
                                "get": "Station",
                                "includes": {
                                    "0": "id",
                                    "1": "name",
                                    "2": "stationAddress",
                                    "3": "stationGeofence",
                                    "4": "parkingLotAddress",
                                    "5": "parkingLotGeofence",
                                    "6": "code",
                                    "7": "timezone"
                                },
                                "excludes": ["company", "skill", "users", "parent", "childrens", "driverRoutes", "payRate", "skillRates", "shifts", "drivers"]
                            },
                            "custom": {
                                functionName: "checkStation",
                                get: 'checkStation',
                                criteria: {
                                    station: id,
                                    company: getCompanyId()
                                },
                                includes: {
                                    "0": "id",
                                    "1": "name",
                                    "2": "code",
                                },
                            }
                        }
                    }
                }
            }
            try {
                let response = await api.post('/api/lazy/manage/data', params);
                if (response.data.data.checkStation == false) {
                    logout().then();
                }
                if(response.data.data.Station){
                    setStation(response.data.data.Station);
                    // if(localStorage.getItem('driverId') !== null){
                    //     getUserStation(response.data.data.Station.id);
                    // }
                }
            } catch(error) {
                await showDefaultErrorToast(error, 'Error finding Station.');
            }
        }
    },[]);

    return (
        <Body>
            { station &&
            <Navigator>
                <Back>
                    <Link to='/company-management/stations'>
                        <Icon.ArrowLeft size='12px' color={Theme.colors.info.border}/>
                        <span>Back to stations list</span>
                    </Link>
                </Back>
                <SubTitle>
                    <Tag inline style={{verticalAlign: 'top'}}>{station.code}</Tag>
                    <Spacer right={2} inline/>
                    {station.name}
                </SubTitle>
                <Spacer top={4}/>
                <Spacer top={4} style={{borderTop: `1px solid ${Theme.colors.info.bg}`}} />
                <Menu>
                    {tabOptions.map((option,key)=>{
                        const Path = `/company-management/station/${station.id}${option.path}`;
                        return (option.isRender &&  <>
                            <IsGranted key={Path} station={station.id} feature={option.feature}>
                                <li>
                                    <NavLink to={{pathname:Path,state:{station:station}}}>
                                        <option.icon size='20px'/>
                                        <span>{option.name}</span>
                                    </NavLink>
                                </li>
                            </IsGranted>
                            {option.path == '/rates' && <li style={{ borderBottom: '1px solid #7c98b6' }}></li>}
                        </>);
                    })}
                </Menu>
                <Spacer top={16} />
            </Navigator>
            }
            { station &&
            <Content>
                <Switch>
                    {tabOptions.map((option,key)=>(
                        <Route path={`/company-management/station/:stationId${option.path}`} key={key}>
                            <IsGranted key={station.id} station={station.id} feature={option.feature} renderWarning={true}>
                                <option.component state={{station:station}} />
                            </IsGranted>
                        </Route>
                    ))}
                </Switch>
            </Content>
            }
        </Body>
    );
};

export default App;
