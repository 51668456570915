import React, { useContext, useRef, useState } from "react";
import LoadOutStore from "./context";
import { useForm } from "react-hook-form";
import {
    Icon,
    Spacer,
    TagInput,
    Theme,
} from "@dspworkplace/ui";
import { Action } from "../Scheduler/Common/UI";
import { getCompanyId, useAuth } from "../../Auth";
import { AddOrEdit } from "./index";

const WaveTimeController = ({ driver, addChange }) => {
    const { api } = useAuth();
    const [{ waveTimeMap }] = useContext(LoadOutStore);
    const { register, getValues, reset } = useForm({});
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState("");

    const callEdit = (w, isAdd = false, hide, isEnter) => {
        addChange({
            description: "setWaveTime",
            callback: (state) => {
                let d = state.drivers.find((d) => d.id === driver.id);
                d.waveTimeReference = ``;
                d.waveTime = `${w}`;
                return state;
            },
        });

        api.post("/api/lazy/manage/data", {
            actions: {
                response: {
                    DriverRouteCode: {
                        custom: {
                            functionName: "updateStagingLocation",
                            get: "stagingLocation",
                            criteria: {
                                company: getCompanyId(),
                                station: driver.stationId,
                                code: driver.routes[0],
                                dateCreated: driver.shiftDate,
                                isArchive: false,
                                stagingLocation: driver.stagingLocation,
                                waveTime: w,
                            },
                        },
                    },
                    WaveTime: {
                        findBy: {
                            get: "WaveTime",
                            criteria: {
                                company: getCompanyId(),
                                station: driver.stationId,
                                isArchive: false,
                            },
                            matching: {
                                waveTime: {
                                    contains: w,
                                },
                            },
                            includes: ["name"],
                        },
                    },
                },
            },
        }).then((response) => {
            reset({
                waveTime: response?.data?.data?.WaveTime?.[0]?.name,
            });
            addChange({
                description: "setWaveTimeReference",
                callback: (state) => {
                    let d = state.drivers.find((d) => d.id === driver.id);
                    d.waveTimeReference = response?.data?.data?.WaveTime?.[0]?.name;
                    if (isAdd) {
                        let waveTimeReference = (d.waveTimeReference != '') ? d.waveTimeReference : w;
                        state.waveTimeMap.push({ name: waveTimeReference, value: waveTimeReference });
                    }
                    return state;
                },
            });
            if (isEnter) {
                setTimeout(() => { hide() }, 50)
            } else {
                hide();
            }
        });
    }

    const handleSave = (hide, isEnter) => {
        let w = getValues("waveTime")?.[0];

        if (!w || !w.trim() || undefined) {
            setTimeout(() => {
                hide();
            }, 50);
            return;
        };

        if (w === driver.waveTime) {
            setTimeout(() => {
                hide();
            }, 50);
            return;
        }
        let editedOption = waveTimeMap.filter((o) => o.value === w);
        // Fix Enter key issue
        if (inputValue == "") {
            if (inputValue == "" && editedOption.length == 0) {
                setTimeout(() => {
                    hide();
                }, 50);
            } else if (editedOption.length != 0) {
                setTimeout(() => {
                    hide();
                }, 50);
            } else {
                setTimeout(() => {
                    hide();
                }, 50);
                return;
            }
        }

        if (editedOption.length == 0) {
            if (driver.routes[0]) {
                addChange({
                    description: "setWaveTime",
                    callback: (state) => {
                        let d = state.drivers.find((d) => d.id === driver.id);
                        d.waveTimeReference = ``;
                        d.waveTime = `${w}`;
                        return state;
                    },
                });

                api.post("/api/lazy/manage/data", {
                    actions: {
                        response: {
                            DriverRouteCode: {
                                custom: {
                                    functionName: "addStagingLocation",
                                    get: "stagingLocation",
                                    criteria: {
                                        company: getCompanyId(),
                                        station: driver.stationId,
                                        code: driver.routes[0],
                                        dateCreated: driver.shiftDate,
                                        isArchive: false,
                                        stagingLocation: driver.stagingLocation,
                                        waveTime: w,
                                    },
                                },
                            },
                        },
                    },
                }).then(() => {
                    callEdit(w, true, hide, isEnter)
                });
            }
        } else {
            callEdit(w, false, hide, isEnter)
        }
    };

    const collection = driver.waveTime
        ? [`${driver.waveTimeReference ? driver.waveTimeReference + "/" : ""}${driver.waveTime}`]
        : [];

    const onChangeHandler = (e) => {
        setInputValue(e.target.value)
    }

    const onClickHandler = (e, hide) => {
        if (typeof inputValue === 'string' && inputValue.trim().length > 0) {
            setInputValue("")
        } else if (typeof inputValue === 'string' && inputValue.trim().length === 0 && e.keyCode === 13) {
            handleSave(hide, true)
        }
    }

    return (
        <AddOrEdit
            collection={collection}
            mode="inline"
            onOpen={() =>
                setTimeout(() => inputRef.current && inputRef.current.parentNode.querySelector("input").focus(), 10)
            }
            enabled={(driver.rescue == false && driver.isRestore != true && driver.shiftType.category != 8 && driver.routes.length > 0) ? true : false}
        >
            {({ hide }) => (
                <>
                    <div onChange={onChangeHandler} onKeyDown={(e) => { onClickHandler(e, hide) }}>
                        <TagInput
                            size={"extraSmall"}
                            name={"waveTime"}
                            ref={(elm) => {
                                inputRef.current = elm;
                                register(elm);
                            }}
                            options={waveTimeMap}
                            singleOption={true}
                            canCreate={true}
                            defaultValues={[driver.waveTime ? driver.waveTime : '']}
                        />
                    </div>
                    <Spacer left={1} inline />
                    <Action type={"primary"} onClick={() => handleSave(hide)}>
                        <Icon.Check color={"white"} size={"20px"} />
                    </Action>
                    <Action type={"cancel"} onClick={hide}>
                        <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                    </Action>
                </>
            )}
        </AddOrEdit>
    );
};

export default WaveTimeController;